import { atom } from 'recoil';
import * as Api from '../ApiV2';
interface IThumbnailState {
  thumbnailCtx: Api.ThumbnailResult[];
  fromPage: number;
  toPage: number;
  selectPages: number[];
}
const thumbnailState = atom({
  key: 'thumbnailState',
  default: {
    thumbnailCtx: [] as Api.ThumbnailResult[],
    fromPage: 1,
    toPage: 5,
    selectPages: [] as number[],
  },
});
export { thumbnailState };
