import styled from 'styled-components';
const StyledLayout = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  .SplitPane {
    overflow: unset !important;
  }
  .splitter-layout {
    .layout-pane.layout-pane-primary {
      overflow: hidden;
    }
  }
  .Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }

  .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }

  .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
  }

  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }

  .Resizer.vertical {
    width: 6px;
    margin: 0 -5px;
    border-left: 1px solid #323130;
    cursor: col-resize;
    background: transparent;
}


  }

  .Resizer.vertical:hover {
    // border-left: 5px solid rgba(0, 0, 0, 0.5);
    // border-right: 5px solid rgba(0, 0, 0, 0.5);
  }
  .Resizer.disabled {
    cursor: not-allowed;
  }
  .Resizer.disabled:hover {
    border-color: transparent;
  }
`;
const StyledContainer = styled.div`
  display: flex;
  height: calc(100% - 106px);
  position: fixed;
  top: 104px;
  width: 100%;
  top: 105px;
  .Pane2 {
    overflow: hidden;
  }  
`;
export { StyledLayout, StyledContainer };
