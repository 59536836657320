import { Checkbox, DefaultButton, Stack, TextField } from '@fluentui/react';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { LocalStorageKey } from '../../../config/constant';
import { useLang } from '../../../i18n/useLang';
import { pageSelectedState } from '../../../recoil';
import { SearchKeywordParamCustom } from '../../../recoil/searchState';
import { documentService } from '../../../service/document';

export default ({
  searchingKeywordParams,
  setSearchingKeywordParams,
}: {
  searchingKeywordParams: SearchKeywordParamCustom;
  setSearchingKeywordParams: (param: SearchKeywordParamCustom) => void;
}) => {
  const [pageState] = useRecoilState(pageSelectedState);
  const { tr } = useLang();
  useEffect(() => {
    localStorage.setItem(
      LocalStorageKey.KeywordSearch,
      JSON.stringify({ ...searchingKeywordParams, ForceSearchWhenInitialized: false, keyword: '' }),
    );
  }, [searchingKeywordParams]);
  return (
    <>
      <Stack verticalAlign="center" tokens={{ childrenGap: 15 }}>
        <div style={{ position: 'relative' }}>
          <TextField
            multiline={true}
            className="mg-top15"
            rows={1}
            disabled={pageState.currentPage <= 0}
            height={40}
            style={{ maxHeight: 40, paddingRight: 25 }}
            value={searchingKeywordParams.keyword || ''}
            onChange={(event, value: any) => {
              setSearchingKeywordParams({ ...searchingKeywordParams, keyword: value || '' });
            }}
            resizable={false}
          />
          <DefaultButton
            className='removebtn'
            styles={{ root: { width: 15 } }}
            disabled={pageState.currentPage <= 0}
            style={{
              position: 'absolute',
              height: 62,
              right: 0,
              top: 10,
              minWidth: 15,
              fontSize: 18,
              padding: 10,
            }}
            onClick={() => {
              setSearchingKeywordParams({ ...searchingKeywordParams, keyword: '' });
            }}
          >
            X
          </DefaultButton>
          {/* <input type="button" value={'x'}></input> */}
        </div>
        <div className="param-search-detail">
          <div className="ms-Grid" dir="ltr">
            <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm6 ">
                  <Checkbox
                    disabled={pageState.currentPage <= 0}
                    label={tr('matchWholeWord')}
                    checked={searchingKeywordParams.wholeWord}
                    onChange={(event, value: any) => {
                      setSearchingKeywordParams({ ...searchingKeywordParams, wholeWord: value });
                    }}
                  />
                </div>
                <div className="ms-Grid-col ms-sm6 ">
                  <Checkbox
                    title={tr('matchCase')}
                    label={tr('matchCase')}
                    disabled={pageState.currentPage <= 0}
                    checked={searchingKeywordParams.matchCase}
                    onChange={(event, value: any) => {
                      setSearchingKeywordParams({ ...searchingKeywordParams, matchCase: value });
                    }}
                  />
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm6 ">
                  <Checkbox
                    label={tr('selectEntireWord')}
                    disabled={pageState.currentPage <= 0}
                    checked={searchingKeywordParams.highlightWholeWord}
                    onChange={(event, value: any) => {
                      setSearchingKeywordParams({ ...searchingKeywordParams, highlightWholeWord: value });
                    }}
                  />
                </div>
                <div className="ms-Grid-col ms-sm6 ">
                  <Checkbox
                    label={tr('useWildcard')}
                    checked={searchingKeywordParams.wildCard}
                    disabled={pageState.currentPage <= 0}
                    onChange={(event, value: any) => {
                      setSearchingKeywordParams({ ...searchingKeywordParams, wildCard: value });
                    }}
                  />
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ">
                  <Checkbox
                    label={tr('allowMultiKeywordsSeparateBy')}
                    disabled={pageState.currentPage <= 0}
                    onChange={() => {
                      setSearchingKeywordParams({
                        ...searchingKeywordParams,
                        searchList: !searchingKeywordParams.searchList,
                      });
                      setTimeout(() => {
                        documentService.CalculateHeightSearchResult();
                      }, 300);
                    }}
                    checked={searchingKeywordParams.searchList}
                  />
                </div>
              </div>
            </Stack>
          </div>
          {searchingKeywordParams.searchList && (
            <div className="ms-Grid" dir="ltr">
              <Stack verticalAlign="center" tokens={{ childrenGap: 10 }} style={{ paddingLeft: 20, marginTop: 15 }}>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm6 ">
                    <Checkbox
                      label={tr('comma')}
                      disabled={pageState.currentPage <= 0}
                      className="sub-checkbox"
                      checked={searchingKeywordParams.byComma}
                      onChange={(event, value: any) => {
                        setSearchingKeywordParams({ ...searchingKeywordParams, byComma: value });
                      }}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm6 ">
                    <Checkbox
                      label={tr('space')}
                      disabled={pageState.currentPage <= 0}
                      className="sub-checkbox"
                      checked={searchingKeywordParams.bySpace}
                      onChange={(event, value: any) => {
                        setSearchingKeywordParams({ ...searchingKeywordParams, bySpace: value });
                      }}
                    />
                  </div>
                </div>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm6 ">
                    <Checkbox
                      label={tr('semicolon')}
                      disabled={pageState.currentPage <= 0}
                      className="sub-checkbox"
                      checked={searchingKeywordParams.bySemicolon}
                      onChange={(event, value: any) => {
                        setSearchingKeywordParams({ ...searchingKeywordParams, bySemicolon: value });
                      }}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm6 ">
                    <Checkbox
                      disabled={pageState.currentPage <= 0}
                      label={tr('slash')}
                      className="sub-checkbox"
                      checked={searchingKeywordParams.bySplash}
                      onChange={(event, value: any) => {
                        setSearchingKeywordParams({ ...searchingKeywordParams, bySplash: value });
                      }}
                    />
                  </div>
                </div>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ">
                    <Checkbox
                      label={tr('line')}
                      disabled={pageState.currentPage <= 0}
                      className="sub-checkbox"
                      checked={searchingKeywordParams.byLine}
                      onChange={(event, value: any) => {
                        setSearchingKeywordParams({ ...searchingKeywordParams, byLine: value });
                      }}
                    />
                  </div>
                </div>
              </Stack>
            </div>
          )}
        </div>
      </Stack>
    </>
  );
};
