import React from 'react';
import { Stack, IconButton } from '@fluentui/react';

type ModalHeaderProps = {
  title: string;
  hiddenClose?: boolean;
  showMax?: boolean;
  onCloseModal?: () => void;
  onMaxModal?: () => void;
};

export default (props: ModalHeaderProps) => (
  <Stack horizontalAlign="space-between" horizontal>
    <h2 style={{ margin: 0, color: '#333' }}>{props.title}</h2>
    <div>
      {props.showMax && (
        <IconButton
          style={{ color: 'black' }}
          iconProps={{ iconName: 'Favicon' }}
          hidden={props.hiddenClose || false}
          ariaLabel="Close popup modal"
          onClick={props.onMaxModal}
        />
      )}

      <IconButton
        style={{ color: 'black', display: props.hiddenClose ? 'none' : 'inline-block' }}
        iconProps={{ iconName: 'Cancel' }}
        hidden={props.hiddenClose || false}
        ariaLabel="Close popup modal"
        onClick={props.onCloseModal}
      />
    </div>
  </Stack>
);
