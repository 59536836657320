import { annotationService } from '../service/annotationService';
import undoManager from './undo-manager';
import { UndoHightLight } from './undoHightLight';
const HighlightPageCommand = (docuViewareID: string | null | undefined, param: any, callback: any) => {
  let ParamUndo = '';
  const HighlightPage = () => {
    const dataRequest = {
      Data: JSON.stringify(param),
      Type: 'HighlightPageCommand',
    };
    annotationService.AddAnnotations(docuViewareID, dataRequest, (res: any) => {
      ParamUndo = res;
      if (callback) {
        callback();
      }
    });
  };
  const UnHighlightPage = () => {
    UndoHightLight(docuViewareID, ParamUndo, callback);
  };
  HighlightPage();
  undoManager.add({
    undo: function () {
      UnHighlightPage();
    },
    redo: function () {
      HighlightPage();
    },
  });
};

export default HighlightPageCommand;
