import { useBoolean } from "@fluentui/react-hooks";
import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import * as Api from "../../../ApiV2";
import { DataCache } from "../../../config/constant";
import SelectionContainer from "../../../control/SelectionContainer/SelectionContainer";
import { useLang } from "../../../i18n/useLang";
import {
  docuviewareMainState,
  pageSelectedState,
  tabState,
  thumbnailState,
} from "../../../recoil";
import { thumbnailService } from "../../../service";
import { documentService } from "../../../service/document";
import { PageThumbnail } from "./pageThumbnail";
import { ThumbnailContextMenu, ThumbnailContextMenuProps } from "./thumbnailContextMenu";
import { SplitDocumentModal } from "../../../modal/SplitDocumentModal";
import { CheckVisibleElement } from "../../../utils/helpers";
const ThumbnailApi = new Api.ThumbnailApi();

let oldScrollPosition = 0;
let timeOutGetThumbnail: any;
interface Position {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
  x: number;
  y: number;
}
interface ThumbnailPanelProps {
  Selected: boolean;
}
export default ({ Selected }: ThumbnailPanelProps) => {
  const [thumbnailCurrentState, setThumbnailState] =
    useRecoilState(thumbnailState);
  const [tabstate] = useRecoilState(tabState);
  const { refreshThumbnail } = thumbnailService();
  const [docuviewareMain,] =
    useRecoilState(docuviewareMainState);
  const [pageState, setPageState] = useRecoilState(pageSelectedState);
  useState<Api.HiddenEnum | null>();
  const [paramSplitDocument, setParamSplitDocument] = useState({
    pageNo: 0,
    tabName: "",
    show: false,
  });
  const [thumbnailContextMenuProps, SetThumbnailContextMenuProps] = useState<ThumbnailContextMenuProps>({
    isShow: false,
    viewerPageNo: 0,
    targetPoint: {},
    thumbnailStatus: null,
    canSplitOnPublish: false
  });
  const StartSelection = useRef<boolean>(false);
  const { tr } = useLang();

  useEffect(() => {
    const pageNos = GetPagesShow();
    if (pageNos.indexOf(pageState.currentPage) < 0) {
      const a = document.getElementById(`thumbnail-${pageState.currentPage}`);
      const container = document.getElementById(`thumbnailSelectionContainer`);
      container?.scrollTo(0, a?.offsetTop || 0);
    }
    if (thumbnailCurrentState.selectPages.length <= 1) {
      setThumbnailState((cur) => {
        return { ...cur, selectPages: [pageState.currentPage] };
      });
    }
  }, [pageState.currentPage]);
  useEffect(() => {
    if ((docuviewareMain?.DocuviewareInit?.tabDetails || []).length > 0) {
      const el = document.getElementById("thumbnailSelectionContainer");
      if (el) {
        const pageNos = GetPagesShow();
        let fromPageNew = 1;
        let toPageNew = 5;
        if (pageNos && pageNos.length > 0) {
          fromPageNew = Math.min(...pageNos) > 1 ? Math.min(...pageNos) - 1 : 1;
          toPageNew = Math.max(...pageNos) + 1;
        }
        setThumbnailState((cur) => {
          return {
            ...cur,
            toPage: toPageNew,
            fromPage: fromPageNew,
          };
        });
        DataCache.StatusLoadAllThumbnail = true;
        refreshThumbnail(fromPageNew, toPageNew).then(() => {
          const a = document.getElementById(
            `thumbnail-${pageState.currentPage}`
          );
          const container = document.getElementById(`thumbnailSelectionContainer`);
          container?.scrollTo(0, a?.offsetTop || 0);
        });
      }
    } else {
      setThumbnailState((cur) => {
        return { ...cur, thumbnailCtx: [] };
      });
    }
  }, []);
  const GetPagesShow = (): number[] => {
    const pageNos: number[] = [];
    const container = document.getElementById(`thumbnailSelectionContainer`);
    thumbnailCurrentState.thumbnailCtx.forEach((tab) => {
      if (tab.imageBases) {
        tab.imageBases.forEach((item) => {
          const el = document.getElementById("thumbnail-" + item.pageNo);
          if (CheckVisibleElement(el, container || undefined)) {
            if (item.pageNo) {
              pageNos.push(item.pageNo);
            }
          }
        });
      }
    });
    return pageNos;
  };
  const loadElementThumbnail = async () => {
    window.clearTimeout(timeOutGetThumbnail);
    timeOutGetThumbnail = setTimeout(async () => {
      const thumbnails = JSON.parse(
        JSON.stringify(thumbnailCurrentState.thumbnailCtx)
      ) as any;
      const el = document.getElementById("thumbnailSelectionContainer");
      if (el && Math.abs(oldScrollPosition - el.scrollTop) > 300 && DataCache.StatusLoadAllThumbnail == false) {
        oldScrollPosition = el.scrollTop;
        const pageNos = GetPagesShow();
        const maxPage = Math.max(...pageNos);
        const minPage = Math.min(...pageNos) + 1;
        if (
          maxPage == thumbnailCurrentState.toPage &&
          minPage == thumbnailCurrentState.fromPage
        ) {
          return;
        }
        const fromPageNew =
          Math.min(...pageNos) > 1 ? Math.min(...pageNos) - 1 : 1;
        const toPageNew = Math.max(...pageNos) + 1;
        const Newthumbnails = (
          await ThumbnailApi.apiLegalxtractGetThumbnailPagesGet(
            fromPageNew,
            toPageNew
          )
        ).data;
        Newthumbnails.forEach((o) => {
          const tab = thumbnails.find(
            (i: any) => i.tabFileInfoId == o.tabFileInfoId
          );
          if (tab) {
            o.imageBases?.forEach((img) => {
              const imgOld = tab?.imageBases?.find(
                (t: any) => t.pageNo == img.pageNo
              );
              if (imgOld) {
                imgOld.isHidden = img.isHidden;
                imgOld.isOCRDone = img.isOCRDone;
                imgOld.imageBase = img.imageBase;
              }
            });
          }
        });
        setThumbnailState((cur) => {
          return {
            ...cur,
            toPage: toPageNew,
            fromPage: fromPageNew,
            thumbnailCtx: [...thumbnails],
          };
        });
      }
      // else {
      //   if (el && DataCache.StatusLoadAllThumbnail == false) {
      //     DataCache.StatusLoadAllThumbnail = true;
      //     const toPage = Math.round(el.offsetHeight / 325);
      //     await refreshThumbnail(1, toPage);
      //   }
      //  DataCache.StatusLoadAllThumbnail = false;
      // }
      DataCache.StatusLoadAllThumbnail = false;
    }, 500);
  };
  const getSelectedItem = (e: any, elementRect: HTMLDivElement | null) => {
    if (!elementRect) return;
    const pageSelects: number[] = [];
    const items = document.getElementsByClassName("item-thumbnail");
    const rect1 = elementRect.getBoundingClientRect();
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      if (
        element &&
        ((element.attributes as any)["itemtype"].nodeValue || "")
          .toString()
          .indexOf("NotSelect") < 0
      ) {
        const rect2 = element.getBoundingClientRect();
        const valueCheck = checkCollisionDetected(rect1, rect2);
        const pageIndex = parseInt(element.id.replace("thumbnail-", ""));
        if (valueCheck) {
          pageSelects.push(pageIndex);
        }
      }
    }
    addPageSelect(e.ctrlKey, pageSelects);
  };
  const checkCollisionDetected = (rect1: Position, rect2: Position) => {
    if (
      rect1.x < rect2.right &&
      rect1.right > rect2.x &&
      rect1.y < rect2.bottom &&
      rect1.bottom > rect2.y
    ) {
      return true;
    }
    return false;
  };
  const addPageSelect = (ctrlKey: boolean, pageSelects: number[]) => {
    let pageOK: number[] = [];
    if (ctrlKey) {
      pageOK = JSON.parse(JSON.stringify(thumbnailCurrentState.selectPages));
    }
    (pageSelects || []).forEach((index) => {
      if (ctrlKey) {
        const page = pageOK.find((o) => o == index);
        if (page) {
          pageOK = pageOK.filter((o) => o != index);
        } else {
          pageOK.push(index);
        }
      } else {
        pageOK.push(index);
      }
    });
    setThumbnailState((cur) => {
      return { ...cur, selectPages: pageOK };
    });
  };
  const addPageSelectShifKey = (shifKey: boolean, pageSelect: number) => {
    let pageOK: number[] = [];
    if (shifKey) {
      pageOK = JSON.parse(JSON.stringify(thumbnailCurrentState.selectPages));
    }
    let minPage = Math.min(...pageOK);
    let maxPage = Math.max(...pageOK);
    if (pageSelect > maxPage) {
      minPage = maxPage;
      maxPage = pageSelect;
    }
    if (pageSelect < maxPage) {
      minPage = pageSelect;
    }
    let imageBases: Api.ImageDetail[] = [];
    thumbnailCurrentState.thumbnailCtx.forEach((o) => {
      imageBases = [...imageBases, ...(o.imageBases || [])];
    });
    const pageSelecteds = imageBases.filter(
      (o) => (o.pageNo || 0) <= maxPage && (o.pageNo || 0) >= minPage
    );
    setThumbnailState((cur) => {
      return { ...cur, selectPages: pageSelecteds.map((o) => o.pageNo || 0) };
    });
  };
  if (!Selected) {
    return null;
  }
  const GetCanSplitOnPublish = (): boolean => {
    let imageBases: Api.ImageDetail[] = [];
    thumbnailCurrentState.thumbnailCtx.forEach((o) => {
      imageBases = [...imageBases, ...(o.imageBases || [])];
    });
    const selectPages = imageBases.filter(
      (o) => thumbnailCurrentState.selectPages.indexOf(o.pageNo || 0) >= 0
    );
    const itemSplitOnExtract = selectPages.filter(
      (o) => o.splitOnExtract == false
    );
    return itemSplitOnExtract && itemSplitOnExtract.length > 0;
  };

  return (
    <>
      <div className="header-left-panel">{tr("thumbnail")}</div>
      <SelectionContainer
        id="thumbnailSelectionContainer"
        style={{
          height: "calc(100% - 58px)",
          overflow: "auto",
        }}
        onEndSelection={(e, elementRect) => {
          StartSelection.current = false;
          getSelectedItem(e, elementRect);
        }}
        onStartSelection={() => {
          StartSelection.current = true;
        }}
        onScroll={async () => {
          await loadElementThumbnail();
        }}
      >
        <div id={"thumbnail_container"}>
          <div
            className={"thumbnail-container"}
          >
            {thumbnailCurrentState.thumbnailCtx.map((x, index) => {
              if (!x.imageBases) return null;
              return (
                <div key={index}>
                  <p style={{ textAlign: "center" }}>
                    <strong>{x.fileName}</strong>
                  </p>
                  {x.imageBases.map((y, index) => {
                    return (
                      <PageThumbnail
                        onClick={(pageNo, e) => {
                          if (e.shiftKey) {
                            addPageSelectShifKey(e.shiftKey, pageNo);
                          } else {
                            addPageSelect(e.ctrlKey, [pageNo]);
                          }
                          documentService.GotoPage(
                            DataCache.docuViewareID(),
                            pageNo || 1
                          );
                        }}
                        onMouseDown={(pageNo, e) => {
                          if (e.button == 2) {
                            if (thumbnailCurrentState.selectPages.indexOf(pageNo) < 0) {
                              addPageSelect(e.ctrlKey, [pageNo]);
                              documentService.GotoPage(
                                DataCache.docuViewareID(),
                                pageNo || 1
                              );
                            }
                          }
                        }}
                        onContextMenu={(pageNo, e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (thumbnailCurrentState.selectPages.length <= 1) {
                            setPageState((cur) => {
                              return { ...cur, currentPage: pageNo || 1 }
                            });
                          }
                          const tab = docuviewareMain.DocuviewareInit?.tabDetails?.find(
                            (o) =>
                              (o.startPage || 0) <= (pageNo || 1) &&
                              (o.startPage || 0) + ((o.totalPage || 0) - 1) >=
                              (pageNo || 1)
                          );
                          let imageBases: Api.ImageDetail[] = [];
                          thumbnailCurrentState.thumbnailCtx.forEach((o) => {
                            imageBases = [
                              ...imageBases,
                              ...(o.imageBases || []),
                            ];
                          });
                          const selectPages = imageBases.filter(
                            (o) =>
                              thumbnailCurrentState.selectPages.indexOf(
                                o.pageNo || 0
                              ) >= 0
                          );
                          const itemShows = selectPages.filter(
                            (o) => o.isHidden == Api.HiddenEnum.NUMBER_0
                          );
                          const itemHides = selectPages.filter(
                            (o) => o.isHidden == Api.HiddenEnum.NUMBER_1
                          );
                          const itemHideOnExtracts = selectPages.filter(
                            (o) => o.isHidden == Api.HiddenEnum.NUMBER_2
                          );
                          let thumbnailStatus: any = null;
                          if (
                            itemShows.length ==
                            thumbnailCurrentState.selectPages.length
                          ) {
                            thumbnailStatus = Api.HiddenEnum.NUMBER_0;
                          } else if (
                            itemHides.length ==
                            thumbnailCurrentState.selectPages.length
                          ) {
                            thumbnailStatus = Api.HiddenEnum.NUMBER_1;
                          } else if (
                            itemHideOnExtracts.length ==
                            thumbnailCurrentState.selectPages.length
                          ) {
                            thumbnailStatus = Api.HiddenEnum.NUMBER_2;
                          } else {
                            thumbnailStatus = null;
                          }

                          if (tab && tab.tabFileType == Api.TabFileType.NUMBER_0) {
                            SetThumbnailContextMenuProps((cur) => {
                              return {
                                ...cur,
                                isShow: true,
                                targetPoint: { x: e.clientX, y: e.clientY },
                                thumbnailStatus: thumbnailStatus,
                                viewerPageNo: pageNo,
                                canSplitOnPublish: GetCanSplitOnPublish()
                              }
                            });
                          }
                        }}
                        imageDetail={y}
                        selectedPage={thumbnailCurrentState.selectPages}
                        active={pageState.currentPage === y.pageNo}
                        pageIndex={y.pageNo}
                        thumbnailStatus={
                          y.isHidden == undefined
                            ? Api.HiddenEnum.NUMBER_0
                            : y.isHidden
                        }
                        key={index}

                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
          {thumbnailContextMenuProps?.isShow && (
            <ThumbnailContextMenu
              hideMenu={() => {

                SetThumbnailContextMenuProps((cur) => {
                  return {
                    ...cur,
                    isShow: false,
                  }
                });
              }}
              param={thumbnailContextMenuProps}
              handleSplitDocument={() => {
                setParamSplitDocument({
                  pageNo: pageState.currentPage || 0,
                  tabName: tabstate.selectedTab?.fileName || "",
                  show: true,
                });
              }}
            />
          )}
          {paramSplitDocument.show && (
            <SplitDocumentModal
              pageNo={paramSplitDocument.pageNo}
              isShow={paramSplitDocument.show}
              tabName={paramSplitDocument.tabName}
              onClose={() => {
                setParamSplitDocument({
                  pageNo: pageState.currentPage || 0,
                  tabName: tabstate.selectedTab?.fileName || "",
                  show: false,
                });
              }}
            ></SplitDocumentModal>
          )}
        </div>
      </SelectionContainer >
    </>
  );
};
