import { Checkbox, DirectionalHint, Stack } from "@fluentui/react";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { Icon } from "@fluentui/react/lib/Icon";
import { Link } from "@fluentui/react/lib/Link";
import {
  ITooltipHostStyles,
  TooltipDelay,
  TooltipHost,
} from "@fluentui/react/lib/Tooltip";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  StyledCustomTemplateInputGroup,
  StyledCustomTemplateItem,
} from "../../../assets/style/left-sidebar/searchTab";
import { LocalStorageKey } from "../../../config/constant";
import { useLang } from "../../../i18n/useLang";
import {
  dialogState,
  modalContainerState,
  pageSelectedState,
  searchState,
} from "../../../recoil";
import { CustomSearchTemplate } from "../../../recoil/searchState";
import { documentService } from "../../../service/document";
import { TemplateSearchDefault } from "./templateSearchDefault";
import { TemplateSearchBelgium } from "./templateSearchBelgium";
import { userService } from "../../../service/userService";
const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: "inline-block" },
};

export default () => {
  const [searchCurrentState, setSearchState] = useRecoilState(searchState);
  const [modalContainer, setModalContainerState] =
    useRecoilState(modalContainerState);
  const [pageState] = useRecoilState(pageSelectedState);
  const [, setDialogState] = useRecoilState(dialogState);
  const { tr } = useLang();
  const { getUser } = userService()
  const handleCustomTemplateClicks = (template: any) => {
    setModalContainerState({ ...modalContainer, editCustomTemplate: template });
  };
  useEffect(() => {
    localStorage.setItem(
      LocalStorageKey.TemplateSearch,
      JSON.stringify(searchCurrentState.searchingTemplateParams)
    );
    setTimeout(() => {
      documentService.CalculateHeightSearchResult();
    }, 300);
  }, [searchCurrentState.searchingTemplateParams]);
  return (
    <>
      <div className="ms-Grid mg-top15" dir="ltr">
        {getUser()?.userDataLogin?.result?.user_info?.countryCode === "BEL" && (
          <TemplateSearchBelgium></TemplateSearchBelgium>
        ) || (<TemplateSearchDefault></TemplateSearchDefault>)}

      </div>
      <Stack
        verticalAlign="center"
        tokens={{ childrenGap: 15 }}
        className="mg-top15"
        style={{ marginLeft: 18 }}
      >
        {searchCurrentState.searchingTemplateParams.templateItem.customTemplate.checked && (
          <StyledCustomTemplateInputGroup className="TemplateInputGroup">
            {searchCurrentState.searchingTemplateParams.customTemplateItems.map(
              (template: CustomSearchTemplate) => {
                return (
                  <StyledCustomTemplateItem key={template.id}>
                    <Checkbox
                      styles={{ root: { flex: 1 } }}
                      label={template.name || ""}
                      disabled={pageState.currentPage <= 0}
                      checked={template.selected}
                      onChange={(e, checked) => {
                        setSearchState((cur) => {
                          return {
                            ...cur,
                            customSearchTemplates:
                              cur.searchingTemplateParams.customTemplateItems.map(
                                (item: CustomSearchTemplate) => {
                                  return item.id == template.id
                                    ? { ...item, selected: checked }
                                    : item;
                                }
                              ),
                          }
                        });
                      }}
                    />
                    <TooltipHost
                      delay={TooltipDelay.zero}
                      directionalHint={DirectionalHint.rightCenter}
                      styles={hostStyles}
                    >
                      <a
                        onClick={() => {
                          handleCustomTemplateClicks(template);
                        }}
                      >
                        <Icon iconName="Edit" style={{ color: "#333" }} />
                      </a>
                    </TooltipHost>
                    <TooltipHost
                      delay={TooltipDelay.zero}
                      directionalHint={DirectionalHint.rightCenter}
                      styles={hostStyles}
                    >
                      <a
                        onClick={() => {
                          setDialogState({
                            titleDialog: "",
                            MsgDialog: tr("doYouWantToDelete"),
                            CallBackConfirmDialog: {
                              action: () => {
                                const a =
                                  searchCurrentState.searchingTemplateParams.customTemplateItems.filter(
                                    (item: any) => item.id !== template.id
                                  );
                                setSearchState((cur) => {
                                  const newObj = {
                                    ...cur,
                                    searchingTemplateParams: {
                                      ...cur.searchingTemplateParams,
                                      customTemplateItems: a,
                                    }
                                  }
                                  return newObj;
                                });
                              },
                              param: null,
                            },
                            Type: "ConfirmationDialog",
                          });
                        }}
                      >
                        <Icon iconName="Cancel" style={{ color: "red" }} />
                      </a>
                    </TooltipHost>
                  </StyledCustomTemplateItem>
                );
              }
            )}
          </StyledCustomTemplateInputGroup>
        )}
      </Stack>
      {searchCurrentState.searchingTemplateParams.templateItem.customTemplate.checked && (
        <Link
          style={{ marginBottom: 0, marginLeft: 18 }}
          disabled={pageState.currentPage <= 0}
          onClick={() => {
            setModalContainerState({
              ...modalContainer,
              openAddCustomTemplate: true,
            });
          }}
        >
          {tr("addCustomTemplate")}
        </Link>
      )}
    </>
  );
};
