import { Tabs } from 'react-tabs';
import styled from 'styled-components';
export const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  background: #f7f7f7;
`;
export const StyledLeftContainer = styled.div`
  height: 100%;
  display: flex;
  background: #fff;
  svg {
    width: 18px;
    height: 18px;
  }
`;

export const StyledTabs = styled(Tabs)<{ disable: string }>`
  height: 100%;
  width: 100%;
  pointer-events: ${(props: any) => (props.disable === '1' ? 'none' : '')};
  .react-tabs {
    display: flex;
    color: rgb(50 49 48);
    height: 100%;
  }
  .ms-TooltipHost {
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .react-tabs__tab-list {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    border-right: 1px solid#b2b2b2;
    border-bottom: 1px solid#b2b2b2;
  }

  .react-tabs__tab {
    height: 27px;
    width: 22px;
    list-style: none;
    padding: 6px 6px 6px 10px;
    cursor: pointer;
    color: #484545;
    text-align: center;
    display: flex;
    align-items: center;
    border-radius: 6px;
    margin: 5px 3px 5px 3px;
    i {
      font-size: 20px;
      margin-top: 3px;
      color: rgb(50, 49, 48);
    }
  }

  .react-tabs__tab--selected {
    background:#b2b2b2;
    border-color: #1c1d1f;
    // border-left: 4px solid rgb(0 120 212);
    color: rgb(50, 49, 48);
    .line{
        width: 3px;
        height: 15px;
        background: #0078d4;
        border-radius: 3px;
        position:absolute;
        left: 4px;
      }
    }
  }
  div[role="toolbar"],div[role="tabpanel"]{
    height: 100%;
  }
  .react-tabs__tab-panel {
    display: none;
    padding: 0 0px 0 10px;
  }
  .param-search{
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 87px);
    padding-left:3px;
  }
  .react-tabs__tab-panel--selected {
    display: block;
    width: 100%;
    width: calc(100% - 69px);
    // border-right: 1px solid#b2b2b2;
    overflow-y: hidden!important;
  }
  .thumbnail-panel {
    height: calc(100% - 37px);
  }

  .panel-content {
    text-align: center;
  }
`;
export const StyledActionIconContainer = styled.a`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 3px;
  margin: 0 5px;
  &:hover {
    background-color: ${(props: any) => props.theme.palette.themePrimary}32;
  }
  i {
    font-size: 15px;
  }
`;
