import { annotationService } from '../service/annotationService';
import { LocalStorageKey } from '../config/constant';
export const UndoHightLight = (docuViewareID: string | null | undefined, param: string, callback: any) => {
  const dataRequest = {
    Data: JSON.stringify(param),
    Type: 'UndoHightLight',
  };
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, 'true');
  annotationService.AddAnnotations(docuViewareID, dataRequest, callback);
};
