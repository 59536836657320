import { useRecoilState } from "recoil";

import * as Api from "../ApiV2";
import { TOCState } from "../recoil/tocState";
const TocApi = new Api.TocApi();
export const TOCService = () => {
  const [, setTOCState] = useRecoilState(TOCState);
  const getTOCInfo = async () => {
    const data = (await TocApi.apiLegalxtractGetTocRowGet()).data;
    setTOCState((cur) => {
      return { ...cur, tocData: data };
    });
  };

  return { getTOCInfo };
};
