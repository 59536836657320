import { useRecoilState } from "recoil";
import { thumbnailState } from "../recoil";
import * as Api from "./../ApiV2";
const ThumbnailApi = new Api.ThumbnailApi();
export const thumbnailService = () => {
  const [, setThumbnailState] = useRecoilState(thumbnailState);
  const refreshThumbnail = async (fromPage: number, toPage: number) => {
    const thumbnails = (
      await ThumbnailApi.apiLegalxtractGetThumbnailAllGet(fromPage, toPage)
    ).data;
    setThumbnailState((cur) => {
      return {
        ...cur,
        thumbnailCtx: thumbnails,
        fromPage: fromPage || 1,
        toPage: toPage,
      };
    });
  };
  return { refreshThumbnail };
};
