import { useRecoilState } from 'recoil';
import * as Api from '../ApiV2';
import { TreeNode } from '../components/left-sidebar/bookmark/tree-bookmark';
import { bookMarkState } from '../recoil';
const BookMarkApi = new Api.BookMarkApi();

export const bookMarkService = () => {
  const [bookMark, SetBookMarkState] = useRecoilState(bookMarkState);

  const getBookMarkList = async () => {
    const BookMarkTextObjs = (await BookMarkApi.apiLegalxtractGetAllBookmarkObjsGet()).data;
    const Nodes = new Array<TreeNode>();
    const roots = new Array<TreeNode>();
    SetBookMarkState((cur) => {
      return {
        ...cur, ListBookmark: BookMarkTextObjs
      }
    });
    BookMarkTextObjs.forEach((el) => {
      const node = {
        childNodes: [],
        isExpand: true,
        id: el.id,
        selected: false,
        title: el.title,
        parentId: el.parentId,
        position: el.position,
      } as TreeNode;
      Nodes.push(node);
    });
    const map = {} as any;
    for (let i = 0; i < Nodes.length; i += 1) {
      map[Nodes[i].id || 0] = i; // initialize the map
    }
    for (let i = 0; i < Nodes.length; i += 1) {
      const node = Nodes[i];
      if (node.parentId) {
        // if you have dangling branches check that map[node.parentId] exists
        Nodes[map[node.parentId || '']].childNodes.push(node);
      } else {
        roots.push(node);
      }
    }
    SetBookMarkState((cur) => {
      return { ...cur, ListBookmark: BookMarkTextObjs, allBookMarks: roots, ShowBookMarkModal: '', itemSelected: roots.find((v, i) => i == 0) || null }
    });
  };

  return { getBookMarkList };
};
