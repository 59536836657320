import { useRecoilState } from "recoil";
import * as Api from "../ApiV2";
import { IReplaceState, pageSelectedState, replaceState } from "../recoil";
import { multiSort } from "../utils/helpers";
const ReplaceApi = new Api.ReplaceApi();
const replaceService = () => {
  const [replace, setReplaceState] = useRecoilState(replaceState);
  const [pageState] = useRecoilState(pageSelectedState);
  const RefreshReplaceWord = async (replaceCurentState: IReplaceState) => {
    ReplaceApi.apiLegalxtractCalculateReplaceGet().then((res) => {
      let replaceObjs = res.data;
      if (replace.sort && replaceObjs.length > 0) {
        replaceObjs = multiSort(replaceObjs, JSON.parse(replace.sort));
      }
      setReplaceState((cur) => {
        return { ...cur, replaceWords: replaceObjs };
      });
    });
  };
  return { RefreshReplaceWord };
};
export { replaceService };
