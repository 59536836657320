import { atom } from "recoil";
import * as Api from "../ApiV2";
import { type } from "os";
export enum OnSelectedFileHandlers {
  UPLOAD_FRONT_PAGE = 0,
  OPEN_FILES = 1,
  ADD_FILES = 2,
}
export class DropdownListProps {
  BlankPageChecked = false;
  OCRChecked = false;
  OptiizedForTextChecked = true;
  TOCChecked = false;
  StampChecked = false;
  nameOfClickedMenuItem = "";
  onSelectedFileHandleKey: OnSelectedFileHandlers =
    OnSelectedFileHandlers.ADD_FILES;
  FrontPageChecked = false;
  IsUploadFrontPage = false;
  PageNumberingChecked = false;
  displayTagOptions = false;
}

export const defaultStampData = {
  title: "",
  automaticCount: true,
  visibility: 50,
  fontName: "Microsoft Sans Serif",
  fontSize: 25,
  index: "",
  logoWidth: 2,
  logoHeight: 2,
  showFromToc: false,
  showStamp: true,
  logoBase64: null,
  fontColor: "#333",
  addFrame: true,
  location: {
    width: 2,
    height: 2,
    marginRight: 0.1,
    marginTop: 0.1,
  },
} as Api.TabStampWeb & { tocIndexPattern?: string };
export const defaultPageNumberingInfo: Api.PageNumberingInfo = {
  fontSize: 12,
  included: false,
  margin: 2,
  marginSide: 2,
  position: 0,
  fontName: "Arial",
  showFromFrontPage: false,
  showFromTOC: false,
  addFrame: true,
  backColor: "#fff",
  fontColor: "#000",
};
interface ITopmenuStripState {
  dropdownList: DropdownListProps;
  stampData: Api.TabStampWeb & { tocIndexPattern?: string };
  pageNumberingInfo: Api.PageNumberingInfo;
}
const topmenuStripState = atom({
  key: "topmenuStripState",
  default: {
    dropdownList: new DropdownListProps(),
    stampData: defaultStampData,
    pageNumberingInfo: defaultPageNumberingInfo,
  } as ITopmenuStripState,
});
export { topmenuStripState };
