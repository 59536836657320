import { useRecoilState } from "recoil";
import * as Api from "../ApiV2";
import HighlightSearchCommand from "../command/hightlight-searchresult-command";
import { DataCache } from "../config/constant";
import { docuviewareMainState, searchState } from "../recoil";
import { CustomSearchTemplate, ISearchState } from "../recoil/searchState";
const SearchApi = new Api.SearchApi();
const searchService = () => {
  const [searchCurrentState, setSearchCurrentState] =
    useRecoilState(searchState);
  const [docuviewareMain] = useRecoilState(docuviewareMainState);
  const onSearchWord = async (currentPage: number, e?: any) => {
    if (e) e.preventDefault();
    if ((docuviewareMain?.DocuviewareInit?.tabDetails || []).length <= 0)
      return;
    let searchPage = currentPage;
    let paramSearch = JSON.parse(JSON.stringify(searchCurrentState)) as ISearchState;
    if (paramSearch.searchTabVm.allPages) {
      searchPage = 0;
    }
    let data = { totalSearchResult: 0 } as Api.SearchResultData;
    let param = {};
    paramSearch = {
      ...paramSearch,
      hasMore: true,
      searchResult: {},
      searchingKeywordParams: {
        ...paramSearch.searchingKeywordParams,
        ForceSearchWhenInitialized: false,
      },
      showLoader: true,
      showCancelLoader: true,
    };
    setSearchCurrentState(JSON.parse(JSON.stringify(paramSearch)));
    if (paramSearch.searchType === "SearchTemplate") {
      const customSearchSelected =
        paramSearch.searchingTemplateParams.customTemplateItems
          .filter((item: CustomSearchTemplate) => {
            return item.selected == true;
          })
          .map((o: CustomSearchTemplate) => o as Api.CustomTemplateItem);
      param = {
        templateItem: Object.values(paramSearch.searchingTemplateParams.templateItem),
        customTemplateItems: customSearchSelected,
        searchTabVm: paramSearch.searchTabVm,
        currentPageNo: searchPage,
      } as Api.SearchTemplateApiParam;
      data = (await SearchApi.apiLegalxtractSearchTemplatePost(param)).data;
    } else if (paramSearch.searchType === "SearchTag") {
      param = {
        pageNo: searchPage,
        matchCase: paramSearch.searchTagParams.matchCase,
        wholeWord: paramSearch.searchTagParams.wholeWord,
        selectedTags: paramSearch.searchTagParams.tagChecked,
        accuracy: paramSearch.searchTabVm.accuracy,
        highlightWholeWord: paramSearch.searchTagParams.highlightWholeWord,
      } as Api.SearchTagParam;
      data = (await SearchApi.apiLegalxtractSearchTagPost(param)).data;
      localStorage.setItem(
        "SearchParamRequest",
        JSON.stringify({
          ParamSearch: JSON.stringify(param || {}),
          SearchType: paramSearch.searchType,
          CacheKey: "",
        })
      );
      if (paramSearch.searchTagParams.searchFrom === "SelectAllTag") {
        HighlightSearchCommand(
          docuviewareMain.DocuviewareInit?.docuViewareID,
          { isSelectAll: true, isHighlight: true },
          (res: any) => {
            setSearchCurrentState({
              ...paramSearch,
              searchResult: res as Api.SearchResultData,
              showLoader: false,
              showCancelLoader: false,
              searchTagParams: {
                ...paramSearch.searchTagParams,
                searchFrom: "",
              },
            });
          }
        );
      } else if (paramSearch.searchTagParams.searchFrom === "UnSelectAllTag") {
        HighlightSearchCommand(
          DataCache.docuViewareID(),
          { isSelectAll: true, isHighlight: false },
          (res: any) => {
            setSearchCurrentState({
              ...paramSearch,
              searchResult: res as Api.SearchResultData,
              showLoader: false,
              showCancelLoader: false,
              searchTagParams: {
                ...paramSearch.searchTagParams,
                searchFrom: "",
              },
            });
          }
        );
      }
      // setSearchCurrentState({
      //   ...paramSearch,
      //   searchTagParams: { ...paramSearch.searchTagParams, searchFrom: '' },
      // });
    } else {
      paramSearch.searchingKeywordParams.keyword =
        paramSearch.searchingKeywordParams.keyword?.trim();
      if (paramSearch.searchingKeywordParams.keyword) {
        if (
          paramSearch.searchingKeywordParams?.wildCard &&
          ((paramSearch.searchingKeywordParams.keyword || "").indexOf("*") >=
            0 ||
            (paramSearch.searchingKeywordParams.keyword || "").indexOf("?") >=
            0)
        ) {
          paramSearch.searchingKeywordParams.wholeWord = true;
          paramSearch.searchingKeywordParams.highlightWholeWord = true;
        }
        param = {
          searchKeywordParam: paramSearch.searchingKeywordParams,
          searchTabVm: paramSearch.searchTabVm,
          currentPageNo: searchPage,
        };
        data = (await SearchApi.apiLegalxtractSearchKeywordPost(param)).data;
      }
    }
    setSearchCurrentState((cur) => {
      return {
        ...paramSearch,
        hasMore: true,
        searchResult: data,
        showLoader: false,
        showCancelLoader: false,
        searchType: cur.searchType,
      };
    });
  };
  return { onSearchWord };
};

export { searchService };
