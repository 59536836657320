import { ContextualMenu, IContextualMenuItem } from "@fluentui/react";
import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import * as Api from "../../../ApiV2";
import { DataCache } from "../../../config/constant";
import { useLang } from "../../../i18n/useLang";
import { TOCState, docuviewareMainState, pageSelectedState, trackingState } from "../../../recoil";
import { documentService } from "../../../service/document";
import { TOCService } from "../../../service";
const JoinDocumentApi = new Api.JoinDocumentApi();
const TocApi = new Api.TocApi();
export const TocContextMenu = ({
  targetPoint,
  hideMenu,
  OnAddBlankPage
}: {
  targetPoint: any;
  hideMenu: () => void;
  OnAddBlankPage: () => void;
}) => {
  const [, setPageState] = useRecoilState(pageSelectedState);
  const [docuviewareMain, setDocuviewareMain] =
    useRecoilState(docuviewareMainState);
  const { tr } = useLang();
  const [tocCurrentState, setTocCurrentState] = useRecoilState(TOCState);
  const [tracking, setTrackingState] = useRecoilState(trackingState);
  useEffect(() => {
    document.addEventListener("mousedown", hideMenu);
    return () => {
      document.removeEventListener("mousedown", hideMenu);
    };
  }, []);
  const isAdjacent = (arr: number[]) => {
    if (arr.length < 2) {
      return false;
    }
    for (let i = 1; i < arr.length; i++) {
      if (arr[i] - arr[i - 1] !== 1) {
        return false;
      }
    }
    return true;
  };

  const UpdateTOC = async (data: Api.TocRow) => {
    setTrackingState({
      ...tracking,
      openingLoadingState: {
        ...tracking.openingLoadingState,
        isOpen: true,
        label: ``,
      },
    });
    const res = await TocApi.apiLegalxtractEditTocRowPost(data);
    setTocCurrentState((cur) => {
      const newarrItem = cur.tocData.filter((o) => o.uniqueId != data.uniqueId);
      const curItem = cur.tocData.find((o) => o.uniqueId == data.uniqueId);
      newarrItem.splice(cur.tocData.indexOf(curItem || {}), 0, data);
      return { ...cur, OrgItems: newarrItem, tocData: newarrItem };
    });
    setDocuviewareMain((current) => {
      return {
        ...current,
        DocuviewareInit: res.data,
      };
    });
    documentService.RefreshPage(null, DataCache.docuViewareID());
    setTrackingState({
      ...tracking,
      openingLoadingState: {
        ...tracking.openingLoadingState,
        isOpen: false,
        label: ``,
      },
    });
  };
  const JoinDocuments = async () => {
    setPageState((cur) => {
      return { ...cur, oldCurrentPage: cur.currentPage, oldCurrentPageId: cur.PageObj?.id || ""  }
    });
    setTrackingState({
      ...tracking,
      openingLoadingState: {
        ...tracking.openingLoadingState,
        isOpen: true,
        label: ``,
      },
    });
    const res = await JoinDocumentApi.apiLegalxtractJoinDocumentsPost({
      tabIds:
        tocCurrentState.tocSelectedItem.map((o) => o.uniqueId || "") || [],
    });
    setTrackingState({
      ...tracking,
      openingLoadingState: {
        ...tracking.openingLoadingState,
        isOpen: false,
        label: ``,
      },
    });
    if (!res.data) return;
    setTocCurrentState((cur) => {
      return { ...cur, tocData: res.data.tocRows || [] };
    });
    setDocuviewareMain((cur) => {
      return {
        ...cur,
        DocuviewareInit: res.data.docuviewareInit || null,
      };
    });
  };
  const items = [] as IContextualMenuItem[];
  if (tocCurrentState.tocSelectedItem.length == 0) return <></>;
  const indexTocSelecteds = tocCurrentState.tocSelectedItem.map((o) =>
    tocCurrentState.tocData.indexOf(o)
  );
  items.push({
    key: "gotoDocument",
    name: tr("goToDocument"),
    onClick: (ev) => {
      hideMenu();
      documentService.GotoPage(
        DataCache.docuViewareID(),
        parseInt(
          (tocCurrentState.tocFocusItem?.page || "").split("‐")[0].trim()
        )
      );
    },
  });
  if (tocCurrentState.tocSelectedItem.length == 1) {
    if (tocCurrentState.tocSelectedItem[0].isTocPageBreak) {
      items.push({
        key: "removePageBreak",
        name: tr("removePageBreak"),
        onClick: (ev) => {
          hideMenu();
          UpdateTOC({
            ...tocCurrentState.tocSelectedItem[0],
            isTocPageBreak: false,
            parentId:
              tocCurrentState.tocSelectedItem[0].parentId ||
              "00000000-0000-0000-0000-000000000000",
          });
        },
      });
    } else {
      items.push({
        key: "pageBreak",
        name: tr("pageBreak"),
        onClick: (ev) => {
          hideMenu();
          setPageState((cur) => {
            return { ...cur, oldCurrentPage: cur.currentPage, oldCurrentPageId: cur.PageObj?.id || ""  }
          });
          UpdateTOC({
            ...tocCurrentState.tocSelectedItem[0],
            isTocPageBreak: true,
            parentId:
              tocCurrentState.tocSelectedItem[0].parentId ||
              "00000000-0000-0000-0000-000000000000",
          });
        },
      });
    }
  }

  if (indexTocSelecteds.length == 1 || isAdjacent(indexTocSelecteds))
    items.push({
      key: "addBlankPage",
      name: tr("addBlankPage"),
      onClick: (ev) => {
        hideMenu();
        OnAddBlankPage();
      },
    });

  if (indexTocSelecteds.length > 1 && isAdjacent(indexTocSelecteds))
    items.push({
      key: "joinDocuments",
      name: tr("joinDocuments"),
      onClick: (ev) => {
        hideMenu();
        JoinDocuments();
      },
    });

  return <ContextualMenu target={targetPoint} items={items} />;
};
