import { atom } from 'recoil';
import * as Api from '../ApiV2';
interface ICommentState {
  ShowCommentModal: string;
  OrderBy: Api.CommentOrderByEnum;
  boxId: string;
  dataModal: Api.CommentObjDisplay | null;
  allComments: Api.CommentObjDisplayWeb[];
  locationMouse: any;
}

export const commentState = atom({
  key: 'commentState',
  default: {
    allComments: new Array<Api.CommentObjDisplayWeb>(),
    ShowCommentModal: '',
    OrderBy: 2,
    dataModal: null as Api.CommentObjDisplay | null,
    locationMouse: { Left: 0, Top: 0 },
    boxId: '',
  },
});
