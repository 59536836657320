import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
const useLang = () => {
  const { t } = useTranslation();
  const tr = (key: string): string => {
    return t(key) || '';
  };
  return { tr, i18n, t };
};
export { useLang };
