import { atom } from "recoil";
import * as Api from "../ApiV2";
import { LocalStorageKey, SearchTypeKey } from "../config/constant";
import { userService } from "../service/userService";
export interface SearchKeywordParamCustom extends Api.SearchKeywordParam {
  ForceSearchWhenInitialized?: boolean;
}
export interface CustomSearchTemplate extends Api.CustomTemplateItem {
  id: string;
}
interface TemplateItemMap {
  phoneNumber: Api.TemplateItem;
  email: Api.TemplateItem;
  diseases: Api.TemplateItem;
  currency: Api.TemplateItem;
  number: Api.TemplateItem;
  webAddress: Api.TemplateItem;
  assistiveProduct: Api.TemplateItem;
  assistiveProductFuzzy: Api.TemplateItem;
  belgianMunicipalities: Api.TemplateItem;
  policeZones: Api.TemplateItem;
  cvr: Api.TemplateItem;
  creditCard: Api.TemplateItem;
  cpr: Api.TemplateItem;
  bsn: Api.TemplateItem;
  nrn: Api.TemplateItem;
  name: Api.TemplateItem;
  nameAccuracy: Api.TemplateItem;
  address: Api.TemplateItem;
  customTemplate: Api.TemplateItem;
  keyword: Api.TemplateItem;
  tag: Api.TemplateItem;
  none: Api.TemplateItem;
  date: Api.TemplateItem;
  beCompanyID: Api.TemplateItem;
  beHandelsregisternummer: Api.TemplateItem;
  beLicensePlates: Api.TemplateItem;
  bePoliceReport: Api.TemplateItem;
  medication: Api.TemplateItem;
}
export interface SearchTemplateParam {
  templateItem: TemplateItemMap;
  customTemplateItems: CustomSearchTemplate[]
}

export interface ISearchState {
  searchingKeywordParams: SearchKeywordParamCustom;
  searchingTemplateParams: SearchTemplateParam;
  searchTabVm: Api.SearchTabVm;
  searchResult: Api.SearchResultData;
  searchType: string;
  hasMore: boolean;
  searchTagParams: any;
  showLoader: boolean;
  showOptionSearchKeyWord: boolean;
  showCancelLoader: boolean;
}
const GetSearchKeywordParam = () => {
  let obj = null;
  const value = localStorage.getItem(LocalStorageKey.KeywordSearch);
  if (value) {
    obj = JSON.parse(value);
  } else {
    obj = { ForceSearchWhenInitialized: false };
  }
  return obj as SearchKeywordParamCustom;
};
const GetSearchTemplateParam = () => {
  const { getUser } = userService()
  let obj = null as SearchTemplateParam | null;
  const value = localStorage.getItem(LocalStorageKey.TemplateSearch);
  if (value) {
    obj = JSON.parse(value) as SearchTemplateParam;
    // set lại default thuộc tính không có trong bản bỉ
    if (getUser()?.userDataLogin?.result?.user_info?.countryCode === "BEL") {
      obj.templateItem.number = {
        templateKey: SearchTypeKey.Number,
        checked: false,
        isFull: false,
      }
      obj.templateItem.assistiveProduct = {
        templateKey: SearchTypeKey.AssistiveProduct,
        checked: false,
        isFull: false,
      }
      obj.templateItem.cpr = {
        templateKey: SearchTypeKey.Cpr,
        checked: false,
        isFull: false,
      }
      obj.templateItem.medication = {
        templateKey: SearchTypeKey.Medication,
        checked: false,
        isFull: false,
      };
    } else {
      obj.templateItem.beCompanyID = {
        templateKey: SearchTypeKey.BECompanyID,
        checked: false,
        isFull: false,
      };
      obj.templateItem.beHandelsregisternummer = {
        templateKey: SearchTypeKey.BEHandelsregisternummer,
        checked: false,
        isFull: false,
      };
      obj.templateItem.beLicensePlates = {
        templateKey: SearchTypeKey.BELicensePlates,
        checked: false,
        isFull: false,
      };
      obj.templateItem.bePoliceReport = {
        templateKey: SearchTypeKey.BEPoliceReport,
        checked: false,
        isFull: false,
      };
      obj.templateItem.belgianMunicipalities = {
        templateKey: SearchTypeKey.BelgianMunicipalities,
        checked: false,
        isFull: false,
      };
      obj.templateItem.bsn = {
        templateKey: SearchTypeKey.Bsn,
        checked: false,
        isFull: false,
      };
      obj.templateItem.nrn = {
        templateKey: SearchTypeKey.Nrn,
        checked: false,
        isFull: false,
      };
    }
  } else {
    const templateItem = {
      phoneNumber: {
        templateKey: SearchTypeKey.PhoneNumber,
        checked: false,
        isFull: false,
      },
      email: {
        templateKey: SearchTypeKey.Email,
        checked: false,
        isFull: false,
      },
      diseases: {
        templateKey: SearchTypeKey.Diseases,
        checked: false,
        isFull: false,
      },
      currency: {
        templateKey: SearchTypeKey.Currency,
        checked: false,
        isFull: false,
      },
      number: {
        templateKey: SearchTypeKey.Number,
        checked: false,
        isFull: false,
      },
      webAddress: {
        templateKey: SearchTypeKey.WebAddress,
        checked: false,
        isFull: false,
      },
      assistiveProduct: {
        templateKey: SearchTypeKey.AssistiveProduct,
        checked: false,
        isFull: false,
      },
      assistiveProductFuzzy: {
        templateKey: SearchTypeKey.AssistiveProductFuzzy,
        checked: false,
        isFull: false,
      },
      belgianMunicipalities: {
        templateKey: SearchTypeKey.BelgianMunicipalities,
        checked: false,
        isFull: false,
      },
      policeZones: {
        templateKey: SearchTypeKey.PoliceZones,
        checked: false,
        isFull: false,
      },
      cvr: {
        templateKey: SearchTypeKey.Cvr,
        checked: false,
        isFull: false,
      },
      creditCard: {
        templateKey: SearchTypeKey.CreditCard,
        checked: false,
        isFull: false,
      },
      cpr: {
        templateKey: SearchTypeKey.Cpr,
        checked: false,
        isFull: false,
      },
      bsn: {
        templateKey: SearchTypeKey.Bsn,
        checked: false,
        isFull: false,
      },
      nrn: {
        templateKey: SearchTypeKey.Nrn,
        checked: false,
        isFull: false,
      },
      name: {
        templateKey: SearchTypeKey.Name,
        checked: false,
        isFull: false,
      },
      nameAccuracy: {
        templateKey: SearchTypeKey.NameAccuracy,
        checked: false,
        isFull: false,
      },
      address: {
        templateKey: SearchTypeKey.Address,
        checked: false,
        isFull: false,
      },
      customTemplate: {
        templateKey: SearchTypeKey.CustomTemplate,
        checked: false,
        isFull: false,
      },
      keyword: {
        templateKey: SearchTypeKey.Keyword,
        checked: false,
        isFull: false,
      },
      tag: {
        templateKey: SearchTypeKey.Tag,
        checked: false,
        isFull: false,
      },
      none: {
        templateKey: SearchTypeKey.None,
        checked: false,
        isFull: false,
      },
      date: {
        templateKey: SearchTypeKey.Date,
        checked: false,
        isFull: false,
      },
      beCompanyID: {
        templateKey: SearchTypeKey.BECompanyID,
        checked: false,
        isFull: false,
      },
      beHandelsregisternummer: {
        templateKey: SearchTypeKey.BEHandelsregisternummer,
        checked: false,
        isFull: false,
      },
      beLicensePlates: {
        templateKey: SearchTypeKey.BELicensePlates,
        checked: false,
        isFull: false,
      },
      bePoliceReport: {
        templateKey: SearchTypeKey.BEPoliceReport,
        checked: false,
        isFull: false,
      },
      medication: {
        templateKey: SearchTypeKey.Medication,
        checked: false,
        isFull: false,
      },
    }

    obj = {
      templateItem: templateItem,
      customTemplateItems: []
    } as SearchTemplateParam
  }
  return obj as SearchTemplateParam;
};
const GetSearchTagParams = () => {
  let obj = null;
  const value = localStorage.getItem(LocalStorageKey.TagSearch);
  if (value) {
    obj = JSON.parse(value);
  } else {
    obj = {
      accuracyValue: 0,
      accuracy: false,
      tagChecked: [],
      selectAll: false,
      searchFrom: "",
    };
  }
  return obj;
};
const GetSearchTabVm = () => {
  let obj = null;
  const value = localStorage.getItem(LocalStorageKey.SearchTabVm);
  if (value) {
    obj = JSON.parse(value);
  } else {
    obj = {
      accuracyValue: 0,
      accuracy: false,
      allPages: true,
      highlightWholeWord: false,
    } as Api.SearchTabVm;
  }
  return obj as Api.SearchTabVm;
};


const searchState = atom<ISearchState>({
  key: "searchState",
  default: {
    searchingKeywordParams: GetSearchKeywordParam(),
    searchingTemplateParams: GetSearchTemplateParam(),
    searchTabVm: GetSearchTabVm(),
    searchResult: {},
    searchType: "SearchKeyword",
    hasMore: false,
    searchTagParams: GetSearchTagParams(),
    showLoader: false,
    showOptionSearchKeyWord: false,
    showCancelLoader: false,
  } as ISearchState,
});
export { searchState };
