import React, { useEffect, useRef, useState } from "react";
let timer: any = null;
interface SelectionContainer
  extends React.ButtonHTMLAttributes<HTMLDivElement> {
  onStartSelection?: () => void;
  onEndSelection?: (e: any, elementRect: HTMLDivElement | null) => void;
}
const SelectionContainer = ({ ...props }: SelectionContainer) => {
  const edgeSize = 20;
  const selectContainer1 = React.useRef<HTMLDivElement>(null);
  const selectContainer2 = React.useRef<HTMLDivElement>(null);
  const elSelectionBox = React.useRef<HTMLDivElement>(null);
  const IsMouseDown = useRef<boolean>(false);
  const selectionBox = useRef<{
    width: number;
    height: number;
    left: number;
    top: number;
  }>({ height: 0, left: 0, top: 0, width: 0 });

  const [marqueeStartPos, SetMarqueeStartPos] = useState({
    x: 0,
    y: 0,
  });
  const [state, SetState] = useState({
    marqueeEndPos: {
      x: 0,
      y: 0,
    },
  });
  useEffect(() => {
    selectionBox.current = {
      width: Math.abs(state.marqueeEndPos.x - marqueeStartPos.x),
      height: Math.abs(state.marqueeEndPos.y - marqueeStartPos.y),
      left: Math.min(marqueeStartPos.x, state.marqueeEndPos.x),
      top: Math.min(marqueeStartPos.y, state.marqueeEndPos.y),
    };
  }, [state]);
  const handleMousemove = (event: any) => {
    if (!IsMouseDown.current) return;
    // Get the viewport-relative coordinates of the mousemove event.
    const container = selectContainer1.current;
    const thumbnail_container = document.getElementById(props.id || "")?.children[0];
    if (!container || !thumbnail_container) return;
    const viewportX = event.clientX;
    const viewportY = event.clientY;
    // Get the viewport dimensions.
    const viewportWidth = container.clientWidth;
    const viewportHeight = container.clientHeight;
    // Next, we need to determine if the mouse is within the "edge" of the
    // viewport, which may require scrolling the window. To do this, we need to
    // calculate the boundaries of the edge in the viewport (these coordinates
    // are relative to the viewport grid system).
    const edgeTop = 200;
    const edgeLeft = edgeSize;
    const edgeBottom = viewportHeight;
    const edgeRight = viewportWidth - edgeSize;

    const isInLeftEdge = viewportX < edgeLeft;
    const isInRightEdge = viewportX > edgeRight;
    const isInTopEdge = viewportY < edgeTop;
    const isInBottomEdge = viewportY > edgeBottom;

    // If the mouse is not in the viewport edge, there's no need to calculate
    // anything else.
    if (!(isInLeftEdge || isInRightEdge || isInTopEdge || isInBottomEdge)) {
      clearTimeout(timer);
      return;
    }

    // If we made it this far, the user's mouse is located within the edge of the
    // viewport. As such, we need to check to see if scrolling needs to be done.

    // Get the document dimensions.
    // --
    // NOTE: The various property reads here are for cross-browser compatibility
    // as outlined in the JavaScript.info site (link provided above).

    const documentWidth = thumbnail_container.scrollHeight;
    const documentHeight = thumbnail_container.scrollHeight;

    // Calculate the maximum scroll offset in each direction. Since you can only
    // scroll the overflow portion of the document, the maximum represents the
    // length of the document that is NOT in the viewport.
    const maxScrollX = documentWidth - viewportWidth;
    const maxScrollY = documentHeight - viewportHeight;

    // As we examine the mousemove event, we want to adjust the window scroll in
    // immediate response to the event; but, we also want to continue adjusting
    // the window scroll if the user rests their mouse in the edge boundary. To
    // do this, we'll invoke the adjustment logic immediately. Then, we'll setup
    // a timer that continues to invoke the adjustment logic while the window can
    // still be scrolled in a particular direction.
    // --
    // NOTE: There are probably better ways to handle the ongoing animation
    // check. But, the point of this demo is really about the math logic, not so
    // much about the interval logic.
    (function checkForWindowScroll() {
      clearTimeout(timer);

      if (adjustWindowScroll()) {
        timer = setTimeout(checkForWindowScroll, 30);
      }
    })();

    // Adjust the window scroll based on the user's mouse position. Returns True
    // or False depending on whether or not the window scroll was changed.
    function adjustWindowScroll() {
      const container = selectContainer1.current;
      const thumbnail_container = document.getElementById(props.id || "")?.children[0];
      if (!container || !thumbnail_container || !IsMouseDown.current) return;
      // Get the current scroll position of the document.
      const currentScrollX = container.scrollLeft;
      const currentScrollY = container.scrollTop;

      // Determine if the window can be scrolled in any particular direction.
      const canScrollUp = currentScrollY > 0;
      const canScrollDown = currentScrollY < maxScrollY;
      const canScrollLeft = currentScrollX > 0;
      const canScrollRight = currentScrollX < maxScrollX;

      // Since we can potentially scroll in two directions at the same time,
      // let's keep track of the next scroll, starting with the current scroll.
      // Each of these values can then be adjusted independently in the logic
      // below.
      let nextScrollX = currentScrollX;
      let nextScrollY = currentScrollY;

      // As we examine the mouse position within the edge, we want to make the
      // incremental scroll changes more "intense" the closer that the user
      // gets the viewport edge. As such, we'll calculate the percentage that
      // the user has made it "through the edge" when calculating the delta.
      // Then, that use that percentage to back-off from the "max" step value.
      const maxStep = 20;

      // Should we scroll left?
      if (isInLeftEdge && canScrollLeft) {
        const intensity = (edgeLeft - viewportX) / edgeSize;

        nextScrollX = nextScrollX - maxStep * intensity;

        // Should we scroll right?
      } else if (isInRightEdge && canScrollRight) {
        const intensity = (viewportX - edgeRight) / edgeSize;

        nextScrollX = nextScrollX + maxStep * intensity;
      }

      // Should we scroll up?
      if (isInTopEdge && canScrollUp) {
        let intensity = (edgeTop - viewportY) / edgeSize;
        intensity = intensity + 1;
        nextScrollY = nextScrollY - maxStep * intensity;

        // Should we scroll down?
      } else if (isInBottomEdge && canScrollDown) {
        const intensity = (viewportY - edgeBottom) / edgeSize;

        nextScrollY = nextScrollY + maxStep * intensity;
      }

      // Sanitize invalid maximums. An invalid scroll offset won't break the
      // subsequent .scrollTo() call; however, it will make it harder to
      // determine if the .scrollTo() method should have been called in the
      // first place.
      nextScrollX = Math.max(0, Math.min(maxScrollX, nextScrollX));
      nextScrollY = Math.max(0, Math.min(maxScrollY, nextScrollY));

      if (nextScrollX !== currentScrollX || nextScrollY !== currentScrollY) {
        container.scrollTo(nextScrollX, nextScrollY);
        return true;
      } else {
        return false;
      }
    }
  };
  const onMouseDown = (e: any) => {
    const rect = e.currentTarget.getBoundingClientRect();
    IsMouseDown.current = true;
    selectContainer2.current?.addEventListener("mouseup", mouseUp);
    SetMarqueeStartPos({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
    SetState({
      marqueeEndPos: {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      },
    });
    startSelection();
    e.preventDefault();
    if (props.onStartSelection) {
      props.onStartSelection();
    }
  };
  const mouseUp = (e: any) => {
    IsMouseDown.current = false;
    if (
      props.onEndSelection &&
      selectionBox.current.height != 0 &&
      selectionBox.current.width != 0
    ) {
      props.onEndSelection(e, elSelectionBox.current);
    }
    selectContainer2.current?.removeEventListener("mouseup", mouseUp);
    selectContainer2.current?.removeEventListener("mousemove", onMouseMove);
    SetState({
      ...state,
      marqueeEndPos: {
        x: 0,
        y: 0,
      },
    });
    SetMarqueeStartPos({
      x: 0,
      y: 0,
    });
    //e.preventDefault()
  };
  const onMouseMove = (e: any) => {
    const rect = e.currentTarget.getBoundingClientRect();
    let oldstate = state;
    oldstate = {
      ...oldstate,
      marqueeEndPos: {
        x: e.pageX - rect.left,
        y: e.pageY - rect.top,
      },
    };
    SetState(oldstate);
    // e.preventDefault()
  };
  const startSelection = () => {
    selectContainer2.current?.addEventListener("mousemove", onMouseMove);
  };
  return (
    <>
      <div
        id={props.id}
        ref={selectContainer1}
        style={{
          height: "calc(100% - 44px)",
          position: "relative",
          overflow: "auto",
        }}
        onScroll={props.onScroll}
        onMouseMove={(e) => {
          handleMousemove(e);
        }}
      >
        <div
          ref={selectContainer2}
          onMouseDown={(e) => {
            onMouseDown(e);
          }}
        >
          {props.children}
          <div
            ref={elSelectionBox}
            style={{
              display: !IsMouseDown.current ? "none" : "block",
              width: Math.abs(state.marqueeEndPos.x - marqueeStartPos.x),
              height: Math.abs(state.marqueeEndPos.y - marqueeStartPos.y),
              position: "absolute",
              cursor: "default",
              zIndex: 99999,
              background: "rgba(0,115,255,.07)",
              border: "solid 1px rgba(72,155,255,.5)",
              pointerEvents: "none",
              boxSizing: "border-box",
              left: Math.min(marqueeStartPos.x, state.marqueeEndPos.x),
              top: Math.min(marqueeStartPos.y, state.marqueeEndPos.y),
            }}
          />
        </div>
      </div>
    </>
  );
};
export default SelectionContainer;
