import * as React from 'react';
import { useLang } from '../../../i18n/useLang';
import { Checkbox, DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';
import * as Api from '../../../ApiV2';
import { documentService } from '../../../service/document';
import { noteService } from '../../../service/noteService';
import { DataCache } from '../../../config/constant';
import { StyledContainer } from '../../../assets/style/left-sidebar/note-panel';
import { useRecoilState } from 'recoil';
import { dialogState, docuviewareMainState, noteState } from '../../../recoil';
import { DocuVieware } from '../../../recoil/docuviewareState';
const NoteApi = new Api.NoteApi();
export default () => {
  const [docuviewareMain, setDocuviewareMain] = useRecoilState<DocuVieware>(docuviewareMainState);
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const [noteCurrentState, setNoteState] = useRecoilState(noteState);
  const { tr } = useLang();

  const COLUMNS: IColumn[] = [
    {
      key: 'Word',
      name: tr('note'),
      fieldName: 'Word',
      minWidth: 80,
      isRowHeader: true,
      className: 'note-content',
      isResizable: true,
      isPadded: true,
      onRender: (item: Api.NoteTextObj) => {
        return (
          <div
            title={item.noteText || ''}
            style={{
              background: item.backColor,
              height: '100%',
              position: 'absolute',
              width: '100%',
              left: 0,
              padding: '0 10px',
            }}
          >
            <span style={{ color: item.foreColor }}>{item.noteText}</span>
          </div>
        );
      },
    },
    {
      key: 'visile',
      name: tr('visibleNote'),
      fieldName: 'visible',
      minWidth: 90,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item: Api.NoteTextObj) => {
        return <Checkbox checked={item.visibleOnExtract} onChange={(e, checked) => onToggleVisible(item, checked)} />;
      },
    },
    {
      key: 'edit',
      name: '',
      fieldName: 'edit',
      minWidth: 9,
      maxWidth: 9,
      onRender: (item: Api.NoteTextObj) => {
        return (
          <div onClick={() => onOpenEditNoteModal(item)}>
            <Icon iconName="Edit" style={{ cursor: 'pointer', fontSize: '17px', width: '17px' }} />
          </div>
        );
      },
      isPadded: false,
    },
    {
      key: 'delete',
      name: '',
      fieldName: 'delete',
      minWidth: 9,
      maxWidth: 9,
      onRender: (item: Api.NoteTextObj) => {
        return (
          <Icon
            onClick={() => {
              setDialogState({
                ...dialog,
                MsgDialog: tr('doYouWantToDelete'),
                CallBackConfirmDialog: { action: onDeleteNote, param: item },
                Type: 'ConfirmationDialog',
              });
            }}
            iconName="Delete"
            style={{ cursor: 'pointer', fontSize: '17px', width: '17px' }}
          />
        );
      },
      isPadded: false,
    },
  ];

  React.useEffect(() => {
    if ((docuviewareMain?.DocuviewareInit?.tabDetails || []).length > 0) {
      refreshNoteList();
    } else {
      setNoteState({ ...noteCurrentState, allNotes: [] });
    }
  }, []);

  const onOpenEditNoteModal = (item: Api.NoteTextObj): void => {
    setNoteState({
      ...noteCurrentState,
      noteModalState: {
        isShowAddNoteModal: 'edit',
        noteBeingEdit: item,
        mousePos: '',
      },
    });
  };

  const onToggleVisible = async (item: Api.NoteTextObj, checked: boolean | undefined) => {
    await NoteApi.apiLegalxtractEditNotePost({
      ...item,
      visibleOnExtract: checked || false,
    });
    refreshNoteList();
  };

  const onDeleteNote = (item: Api.NoteTextObj) => {
    NoteApi.apiLegalxtractDeleteNotePost(item.id || '').then(() => {
      documentService.RefreshPage(null, DataCache.docuViewareID());
      refreshNoteList();
    });
  };
  const refreshNoteList = () => {
    noteService.refreshNoteList().then((data) => {
      setNoteState({ ...noteCurrentState, allNotes: data });
    });
  };
  return (
    <StyledContainer>
      <div className="header-left-panel">{tr('note')}</div>
      <div
        style={{
          height: 'calc(100% - 39px)',
          overflow: 'auto',
        }}
      >
        <DetailsList
          items={noteCurrentState.allNotes}
          columns={COLUMNS}
          selectionMode={SelectionMode.none}
          setKey="none"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
        />
      </div>
    </StyledContainer>
  );
};
