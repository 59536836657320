import React, { useEffect, useContext } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { useLang } from '../../i18n/useLang';
import { StyledSearchSpinner, StyledSearchLoader } from '../../assets/style/control/loadding';

const LoaderSearch = ({
  showCancelLoader,
  showLoader,
  onClickCancel,
}: {
  showCancelLoader: boolean;
  showLoader: boolean;
  onClickCancel: () => void;
}) => {
  const { tr } = useLang();
  return (
    <>
      {showLoader && (
        <>
          <StyledSearchLoader className="loadding">
            <StyledSearchSpinner className="spinner" />
            {showCancelLoader && (
              <PrimaryButton
                onClick={() => {
                  onClickCancel();
                }}
              >
                {tr('cancel')}
              </PrimaryButton>
            )}
          </StyledSearchLoader>
        </>
      )}
    </>
  );
};

export default LoaderSearch;
