import Cookies from "js-cookie";
import React, { Suspense, useEffect, useState } from "react";
import SplitterLayout from "react-splitter-layout";
import "react-splitter-layout/lib/index.css";
import { useRecoilState } from "recoil";
import * as Api from "./ApiV2";
import globalAxiosConfig from "./ApiV2/axiosConfig";
import "./App.css";
import { StyledContainer, StyledLayout } from "./assets/style/app";
import { JobCheckCookeis } from "./components/cronjob/JobCheckCookeis";
import {
  CompanyCode,
  Constants,
  DataCache,
  LocalStorageKey,
} from "./config/constant";
import LoadingFullscreen from "./control/loading/loading-fullscreen";
import { GoToNova } from "./modal/SaveFileModal";
import {
  Annotation,
  IMouseState,
  IPageState,
  IWordState,
  dialogState,
  leftSidebarState,
  trackingState,
} from "./recoil";
import { documentService } from "./service/document";
import { DocuVieware, lxSettingDefault, lxSettingState } from "./recoil/docuviewareState";
import { userService } from "./service/userService";
import { axiosInstance } from "./config/axios-config";
import { useLang } from "./i18n/useLang";
import { TabContextMenuProps } from "./components/layouts/menuContext/mainMenuContext";
import { useNavigate } from "react-router-dom";
import { getParameterByName } from "./utils/helpers";
const MenuTrip = React.lazy(() => import("./components/topMenu/topMenuTrip"));
const LeftSidebar = React.lazy(
  () => import("./components/left-sidebar/left-sidebar-container")
);
const MenusIcon = React.lazy(() => import("./components/topMenu/menusIcon"));
const HomePage = React.lazy(() => import("./containers/home-page"));
const OpeningApi = new Api.OpeningApi();
const TestApi = new Api.TestApi();
let timeout: any;
let ischeckFosCusTab = false;
export const CacheApp = {
  FileInputAccept: "",
  AnnotState: null as Annotation | null,
  MainMenuContext: null as TabContextMenuProps | null,
  DocuviewareMain: null as DocuVieware | null,
  PageState: null as IPageState | null,
  WordCurrentState: null as IWordState | null,
  MouseModeState: null as IMouseState | null,
  LineBorderWidth: { borderEdit: 0.02, colorEdit: "#323130", borderAdd: 0.02, colorAdd: "#323130" } as {
    borderEdit?: number;
    colorEdit?: string;
    borderAdd?: number;
    colorAdd?: string;
  } | null,
  LicenseInfo: {} as Api.LicenseInfoWeb,
  KeyCode:null as number | null,
}
export const App = () => {
  const { tr } = useLang();
  const { getUser } = userService();
  const { runJob } = JobCheckCookeis();
  const [openModal, setOpenModalUser] = useState("");
  const [leftSidebar] = useRecoilState(leftSidebarState);
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const [tracking, setTrackingState] = useRecoilState(trackingState);
  const navigate = useNavigate();
  const [lxSetting, setLxSettingState] = useRecoilState(lxSettingState);

  useEffect(() => {
    globalAxiosConfig();
    runJob();
    const user = getUser();
    if (user) {
      if (!DataCache.REACT_APP_CompanyCode) {
        axiosInstance
          .post(
            DataCache.REACT_APP_API_ENDPOINT + "/api/legalxtract/CheckUserStatus",
            {
              token: user.userDataLogin?.result?.access_token,
            }
          )
          .then(async (res) => {
            const a = res.data.result as Api.UserStatusResult;
            if (a.status != 1) {
              window.location.href = "/login";
            } else {
              let msg = ""
              if (a.licenseData?.mode == "invalid") {
                if (a.licenseData.description == "key expired") {
                  msg = "Your time to use the software is over."
                } else {
                  msg = "You no longer have the right to use the software"
                }
              }
              if (msg) {
                setDialogState({
                  ...dialog,
                  MsgDialog: msg,
                  CallBackConfirmDialog: {
                    action: () => {
                      window.location.href = "/login";
                    },
                    param: null,
                  },
                  Type: "WarningDialog",
                });
              }
            }
          });
      }
    }

    OpeningApi.apiLegalxtractFileTypeSupportGet().then((res) => {
      const data = res.data;
      let acceptFileExtensions: string[] = [];
      Object.keys(data).forEach((key: string) => {
        acceptFileExtensions = acceptFileExtensions.concat(
          data[key].split(";").map((ext: any) => `.${ext.toLowerCase()}`)
        );
      });
      CacheApp.FileInputAccept = acceptFileExtensions.join(",") || "";
    });
    const setting = localStorage.getItem(LocalStorageKey.LxSetting);
    if (!setting) {
      let lxSetting = lxSettingDefault;
      if (DataCache.REACT_APP_CompanyCode ==  CompanyCode.Nova) {
        lxSetting = {...lxSetting, dictionaryName:Api.Country.NUMBER_1, namesWillNotBeginOrEndWith:''} 
      }
      localStorage.setItem(
        LocalStorageKey.LxSetting,
        JSON.stringify(lxSetting)
      );
      setLxSettingState(lxSetting);
    }
    document.addEventListener("visibilitychange", function () {
      if (!document.hidden && !window.location.href.includes("/login")) {
        if (ischeckFosCusTab == false) {
          ischeckFosCusTab = true;
          TestApi.apiLegalxtractDocuViewareHealthCheckGet().then(() => {
            ischeckFosCusTab = false;
          });
        }
      }
    });
    const msg = tr("yourSessionHasExpiredGoToNOVA");
    const tokenThirdParty = Cookies.get(Constants.TokenThirdParty);
    if (
      !tokenThirdParty &&
      DataCache.REACT_APP_CompanyCode?.toLowerCase() == CompanyCode.Nova
    ) {
      setTrackingState({ ...tracking, showMSGExpireCookie: true });
      setDialogState({
        ...dialog,
        MsgDialog: msg,
        CallBackConfirmDialog: {
          action: () => {
            setTrackingState({ ...tracking, showMSGExpireCookie: false });
            GoToNova();
          },
          param: null,
        },
        Type: "AlertDialog",
      });
    }
    const docId = getParameterByName('docId', window.location.href);
    if (DataCache.REACT_APP_CompanyCode?.toLowerCase() == CompanyCode.lc && docId) {
      navigate("/")
    }
  }, []);
  useEffect(() => {
    const el = document.getElementsByClassName("layout-pane")[0];
    if (el) {
      el.setAttribute("style", `width: ${leftSidebar.SecondaryInitialSize}px;`);
    }
  }, [leftSidebar.SecondaryInitialSize]);
  return (
    <>
      <LoadingFullscreen />
      {/* <LoadingFallback /> */}
      <StyledLayout>
        <Suspense
          fallback={
            <>
              <div className="loader-content">
                <div className="loader-fallback"></div>
                <h2>{tr('loadingComponents')}</h2>
              </div>
            </>
          }
        >
          {<MenuTrip />}
          {<MenusIcon />}
          <StyledContainer>
            <SplitterLayout
              onSecondaryPaneSizeChange={() => {
                documentService.centerScroll();
                if (timeout) {
                  clearTimeout(timeout);
                }
                timeout = setTimeout(() => {
                  DocuViewareAPI.UpdateLayout(DataCache.docuViewareID());
                  timeout = null;
                }, 150);
              }}
              secondaryInitialSize={leftSidebar.SecondaryInitialSize}
              primaryMinSize={800}
              primaryIndex={1}
              customClassName="SplitterLayout-Custome"
            >
              {<LeftSidebar />}
              {
                <HomePage
                  openModal={openModal}
                  setOpenModal={() => {
                    setOpenModalUser("");
                  }}
                />
              }
            </SplitterLayout>
          </StyledContainer>
        </Suspense>
      </StyledLayout>
    </>
  );
};
