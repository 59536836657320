import { useRecoilState } from 'recoil';
import * as Api from '../ApiV2';
import { historyState } from './../recoil/historyState';
const HistoryApi = new Api.HistoryApi();
export const historyService = () => {
  const [, setHistoryState] = useRecoilState(historyState);
  const getHistoryView = async (tabId: string) => {
    const history = (await HistoryApi.apiLegalxtractGetDataOfHistoryFileGet(tabId)).data;
    setHistoryState((cur) => {
      return { ...cur, HistoryView: history };
    });
  };
  return { getHistoryView };
};
