import {
  dialogState,
  lxSettingState,
  ocrSocketSignalRState,
  pageSelectedState,
  trackingState,
} from "./../recoil";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";
import { Utility } from "../config/utility";
import * as Api from "../ApiV2";
import { useRecoilState } from "recoil";
import { leftSidebarState } from "../recoil";
import { documentService } from "./document";
import { DataCache, LocalStorageKey } from "../config/constant";
import { userService } from "./userService";
export const baseAPI = `${Utility.baseUrl()}`;
const OcrApi = new Api.OcrApi();
let totalOCRPages = 0;
export const ocrSocketSignalRSService = () => {
  const [, setPageState] = useRecoilState(pageSelectedState);
  const [lxSetting,] = useRecoilState(lxSettingState);
  const [, setDialogState] = useRecoilState(dialogState);
  const [OcrSocketSignalR, setOcrSocketSignalRState] = useRecoilState(
    ocrSocketSignalRState
  );
  const { getUser } = userService()
  const [, setTrackingState] = useRecoilState(trackingState);
  const GetOcrid = () => {
    let ocrId = localStorage.getItem(LocalStorageKey.OcrId);
    if (ocrId) {
      return ocrId;
    } else {
      ocrId = uuidv4();
      localStorage.setItem(LocalStorageKey.OcrId, ocrId);
      return ocrId;
    }
  }
  const ocrDocument = (ocrType: "OCRCurrentPage" | "OCRCurrentTab" | "OCRAllTabs",
    OCRCurrentPageParam: {
      currentTab: Api.TabDetail,
      currentPage: number
    } | null,
    currentTab: Api.TabDetail | null,
    tabs: Api.TabDetail[] | null, isOpenFile?: boolean) => {
    const email = getUser()?.userDataLogin?.result?.user_info?.email;
    if (OcrSocketSignalR.OcrProessing && email && !checkNotSendMailRunOcr(ocrType, currentTab, tabs)) {
      setDialogState((cur) => { return { ...cur, MsgDialog: `Your document is currently being processed, and once the OCR is complete, we will send an email to the address ${email}.`, Type: 'WarningDialog' } });
      return;
    }
    // if (isOpenFile) {
    //   runOcr(ocrType, OCRCurrentPageParam, currentTab, tabs);
    //   return;
    // }
    if (checkNotSendMailRunOcr(ocrType, currentTab, tabs)) {
      runOcr(ocrType, OCRCurrentPageParam, currentTab, tabs);
    } else {
      setDialogState((cur) => {
        return {
          ...cur, MsgDialog: `You can leave the browser window open, and you follow the progress of the OCR process. An e-mail will be send to ${email} when the process is done.  <br>
          Alternatively, you can close the browser window, and we will send an email to ${email} once the OCR process is done. The email will include a link that allows you to reopen the document with the completed OCR.`,
          'Type': 'AlertDialog',
          CallBackConfirmDialog: {
            action: () => {
              runOcr(ocrType, OCRCurrentPageParam, currentTab, tabs);
            }, param: null
          }
        }
      })
    }

  }
  const checkNotSendMailRunOcr = (ocrType: "OCRCurrentPage" | "OCRCurrentTab" | "OCRAllTabs",
    currentTab: Api.TabDetail | null,
    tabs: Api.TabDetail[] | null): boolean => {
    const email = getUser()?.userDataLogin?.result?.user_info?.email;
    if (!email) return true;
    switch (ocrType) {
      case "OCRAllTabs": {
        const totalOcr = tabs?.map(o => o.numberPageNeedOCR).reduce((accumulator, currentValue) => {
          return (accumulator || 0) + (currentValue || 0)
        }, 0);
        return (totalOcr || 0) <= (lxSetting.dontSendOCRMailPageCount || 100)
      }
      case "OCRCurrentPage": {
        return true;
      }
      case "OCRCurrentTab": {
        return (currentTab?.numberPageNeedOCR || 0) <= (lxSetting.dontSendOCRMailPageCount || 100);
      }
    }
  }
  const runOcr = (ocrType: "OCRCurrentPage" | "OCRCurrentTab" | "OCRAllTabs",
    OCRCurrentPageParam: {
      currentTab: Api.TabDetail,
      currentPage: number
    } | null,
    currentTab: Api.TabDetail | null,
    tabs: Api.TabDetail[] | null) => {

    switch (ocrType) {
      case "OCRAllTabs": {
        if (tabs)
          handleOCRAllTabs(tabs)
        break
      }
      case "OCRCurrentPage": {
        if (OCRCurrentPageParam)
          handleOCRCurrentPage(OCRCurrentPageParam.currentTab, OCRCurrentPageParam.currentPage);
        break
      }
      case "OCRCurrentTab": {
        if (currentTab)
          handleOCRCurrentTab(currentTab);
        break
      }
    }
  }
  const handleOCRCurrentPage = (
    currentTab: Api.TabDetail,
    currentPage: number
  ) => {
    if (OcrSocketSignalR.ocrProgress > 0) return;
    setOcrSocketSignalRState((cur) => {
      return {
        ...cur,
        cancelOCR: false,
        ocrProgress: 0.0001,
        PreparingPage: {
          OCRTotalPages: 0,
          OCRingPages: 0,
        },
        OCRing: {
          OCRTotalPages: 0,
          OCRingPages: 0,
        },
        ReadingFile: {
          OCRTotalPages: 0,
          OCRingPages: 0,
        },
      };
    });
    const ocrId = GetOcrid();
    totalOCRPages = 1;

    setPageState((cur) => {
      return { ...cur, oldCurrentPage: cur.currentPage, oldCurrentPageId: cur.PageObj?.id || "" }
    });
    setTrackingState((cur) => {
      return { ...cur, openingLoadingState: { isOpen: true, label: '' } }
    });
    OcrApi.apiLegalxtractOcrProcessPost({
      ocrId: ocrId,
      ocrMode: Api.OcrMode.NUMBER_1,
      tabId: currentTab?.uniqueId ? [currentTab?.uniqueId] : [],
      email: getUser()?.userDataLogin?.result?.user_info?.email,
      pages: [currentPage],
    }).then((res) => {
      setTrackingState((cur) => {
        return { ...cur, openingLoadingState: { isOpen: false, label: '' } }
      });
      if (res.data && res.data.length == 0) {
        setOcrSocketSignalRState((cur) => {
          return {
            ...cur,
            ocrProgress: 0,
          }
        });
      }
    });
  };

  const handleOCRCurrentTab = (currentTab: Api.TabDetail) => {
    if (currentTab.numberPageNeedOCR == 0 || OcrSocketSignalR.ocrProgress > 0)
      return;
    setOcrSocketSignalRState((cur) => {
      return {
        ...cur,
        cancelOCR: false,
        ocrProgress: 0.0001,
        PreparingPage: {
          OCRTotalPages: 0,
          OCRingPages: 0,
        },
        OCRing: {
          OCRTotalPages: 0,
          OCRingPages: 0,
        },
        ReadingFile: {
          OCRTotalPages: 0,
          OCRingPages: 0,
        },
      }
    });
    const ocrId = GetOcrid();
    setPageState((cur) => {
      return { ...cur, oldCurrentPage: cur.currentPage, oldCurrentPageId: cur.PageObj?.id || "" };
    });

    setTrackingState((cur) => {
      return { ...cur, openingLoadingState: { isOpen: true, label: '' } }
    });
    OcrApi.apiLegalxtractOcrProcessPost({
      ocrId: ocrId,
      ocrMode: Api.OcrMode.NUMBER_1,
      tabId: currentTab?.uniqueId ? [currentTab?.uniqueId] : [],
      email: getUser()?.userDataLogin?.result?.user_info?.email,
      pages: [],
    }).then((res) => {
      setTrackingState((cur) => {
        return { ...cur, openingLoadingState: { isOpen: false, label: '' } }
      });
      if (res.data && res.data.length == 0) {
        setOcrSocketSignalRState((cur) => {
          return {
            ...cur,
            ocrProgress: 0,
          };
        });
      }
    });
  };

  const handleOCRAllTabs = (tabs: Api.TabDetail[]) => {
    if (OcrSocketSignalR.ocrProgress > 0) return;
    const tabIds = [] as string[];
    let totalPages = 0;
    tabs.forEach((tab) => {
      totalPages += tab.numberPageNeedOCR!;
      tabIds.push(tab.uniqueId || "");
    });
    if (totalPages === 0) return;
    setOcrSocketSignalRState((cur) => {
      return {
        ...cur,
        cancelOCR: false,
        ocrProgress: 0.0001,
        PreparingPage: {
          OCRTotalPages: 0,
          OCRingPages: 0,
        },
        OCRing: {
          OCRTotalPages: 0,
          OCRingPages: 0,
        },
        ReadingFile: {
          OCRTotalPages: 0,
          OCRingPages: 0,
        },
      };
    });

    totalOCRPages = totalPages;
    setPageState((cur) => {
      return { ...cur, oldCurrentPage: cur.currentPage, oldCurrentPageId: cur.PageObj?.id || "" };
    });
    const ocrId = GetOcrid();
    setTrackingState((cur) => {
      return { ...cur, openingLoadingState: { isOpen: true, label: '' } }
    });
    OcrApi.apiLegalxtractOcrProcessPost({
      ocrId: ocrId,
      ocrMode: Api.OcrMode.NUMBER_1,
      tabId: tabIds,
      email: getUser()?.userDataLogin?.result?.user_info?.email,
      pages: [],
    }).then((res) => {
      setTrackingState((cur) => {
        return { ...cur, openingLoadingState: { isOpen: false, label: '' } }
      });
      if (res.data && res.data.length == 0) {
        setOcrSocketSignalRState((cur) => {
          return {
            ...cur,
            ocrProgress: 0,
          };
        });
      }
    });
  };

  const handleCancelOCR = async (updateTabs?: any) => {
    const ocrId = GetOcrid();
    const status = await OcrApi.apiLegalxtractCancelOcrPost(
      ocrId || ""
    );
    setOcrSocketSignalRState((cur) => {
      return {
        ...cur,
        ocrProgress: 0,
        cancelOCR: true,
      };
    });
    documentService.RefreshPage(null, DataCache.docuViewareID());
  };
  const ReadFileOCrDone = async (ocrid: string) => {
    let Id = ocrid
    if (!ocrid) {
      Id = GetOcrid();
    }
    OcrApi.apiLegalxtractReadFileOCrDonePost({
      ocrId: Id || "",
    });
  };

  return {
    ocrDocument,
    handleCancelOCR,
    ReadFileOCrDone,
    GetOcrid
  };
};
