import { atom } from "recoil";
import * as ApiV2 from "../ApiV2";
import { CompanyCode, DataCache, LocalStorageKey } from "../config/constant";

class DocuVieware {
  DocuviewareInit: ApiV2.DocuviewareInit | null = null;
  openPreview?: boolean;
  docuViewarePreviewID?: string | undefined | null;
  previewSessionID?: string | undefined | null;
  previewHtmlString?: string | undefined | null;
  pageDetails: ApiV2.WordInPage[] = [];
  isRefeshDocument?: boolean;
}
export const lxSettingDefault = {
  replaceFontName: "Arial",
  option_Color_selection: "yellow",
  highlightPublishColor: "black",
  extractCombineTab: true,
  replaceFontSize: 10,
  extractMaxFileSize: 0,
  autoHighlightMask: true,
  archiveFileFormat: 1,
  oCRWhenOpeningDocuments: false,
  compressExtractFiles: false,
  extractPdfFileType: ApiV2.PdfFileType.NUMBER_1,
  warningKeyword: false,
  warningTemplate: false,
  warningTag: false,
  tocIndexPattern: "1",
  drawIndexInTocPages: true,
  dictionaryName:
    ApiV2.Country.NUMBER_1 |
    ApiV2.Country.NUMBER_2 |
    ApiV2.Country.NUMBER_4 |
    ApiV2.Country.NUMBER_8 |
    ApiV2.Country.NUMBER_32768,
  namesWillNotBeginOrEndWith: `la, von, van, le, du, dos, di, á, van't, zum, við, del, ter, te, í, ten, el, los, das, des, a, av, d', han, en, de, kilde, den, det, dem, der, fam, vi, af, om, et, da, ip, no, ja, ny, id no, kan, er, man, in, the, til, dear, legal, ek, dir, nem, id, jeg, se, tak, raad, voor, about, us`,
  dontSendOCRMailPageCount: 100
} as ApiV2.LxSetting;
export type { DocuVieware };
const docuviewareMainState = atom<DocuVieware>({
  key: "docuviewareMain",
  default: {
    DocuviewareInit: null,
    openPreview: false,
    docuViewarePreviewID: null,
    previewSessionID: null,
    previewHtmlString: null,
    pageDetails: [],
    isRefeshDocument: false,
  } as DocuVieware,
});
const GetLxSetting = () => {
  const lx = localStorage.getItem(LocalStorageKey.LxSetting);
  if (lx) {
    const obj = JSON.parse(lx) as ApiV2.LxSetting;
    if (!obj.dictionaryName) {
      obj.dictionaryName = (ApiV2.Country.NUMBER_1 |
        ApiV2.Country.NUMBER_2 |
        ApiV2.Country.NUMBER_4 |
        ApiV2.Country.NUMBER_8 |
        ApiV2.Country.NUMBER_32768) as ApiV2.Country;
    }
    if(obj.dontSendOCRMailPageCount == undefined || obj.dontSendOCRMailPageCount == null){
      obj.dontSendOCRMailPageCount = 100;
    }
    return obj;
  }
  return lxSettingDefault;
};
const docuviewarePreviewState = atom<DocuVieware>({
  key: "docuviewarePreview",
  default: {} as DocuVieware,
});
const currentPagePreviewState = atom<number>({
  key: "currentPagePreview",
  default: 1,
});
const lxSettingState = atom<ApiV2.LxSetting>({
  key: "lxSetting",
  default: GetLxSetting(),
});
const zoomModeState = atom<ApiV2.ViewerZoomMode | null>({
  key: "zoomMode",
  default: 3 as ApiV2.ViewerZoomMode | null,
});

export {
  docuviewareMainState,
  docuviewarePreviewState,
  currentPagePreviewState,
  zoomModeState,
  lxSettingState,
};
