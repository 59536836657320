import * as Api from '../ApiV2';
import { LocalStorageKey } from '../config/constant';
import { annotationService } from '../service/annotationService';
import undoManager from './undo-manager';
interface RemoveBox {
  boxId: string;
  viewerPageNo: number;
}
interface AddBox {
  addBoxParam: Api.AddBoxParam;
  viewerPageNo: number;
}
const BoxCommand = (
  docuViewareID: string | null | undefined,
  paramAddBox: AddBox | null,
  paramRemoveBox: RemoveBox | null,
  callback: any,
) => {
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, 'true');
  let ParamAddBox: AddBox | null = null;
  let ParamRemoveBox: RemoveBox | null = null;
  const Callback = callback;
  const AddBox = (paramAddBox: AddBox | null) => {
    if (!paramAddBox) return;
    const dataRequest = {
      Data: JSON.stringify(paramAddBox),
      Type: 'AddBoxCommand',
    };
    annotationService.AddAnnotations(docuViewareID, dataRequest, (res: any) => {
      const objData = JSON.parse(res);
      ParamRemoveBox = {
        boxId: objData.id,
        viewerPageNo: paramAddBox.viewerPageNo,
      } as RemoveBox;
      if (Callback) {
        Callback();
      }
    });
  };
  const RemoveBox = (paramRemoveBox: RemoveBox | null) => {
    if (!paramRemoveBox) return;
    const dataRequest = {
      Data: JSON.stringify(paramRemoveBox),
      Type: 'RemoveBoxCommand',
    };
    annotationService.AddAnnotations(docuViewareID, dataRequest, (res: any) => {
      const objData = JSON.parse(res);
      ParamAddBox = {
        addBoxParam: objData,
        viewerPageNo: paramRemoveBox.viewerPageNo,
      };
      if (Callback) {
        Callback();
      }
    });
  };
  // delete
  if (paramRemoveBox) {
    RemoveBox(paramRemoveBox);
    undoManager.add({
      undo: function () {
        AddBox(ParamAddBox);
      },
      redo: function () {
        RemoveBox(ParamRemoveBox);
      },
    });
  } else {
    AddBox(paramAddBox);
    undoManager.add({
      undo: function () {
        RemoveBox(ParamRemoveBox);
      },
      redo: function () {
        AddBox(ParamAddBox);
      },
    });
  }
};

export default BoxCommand;
