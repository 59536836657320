import React, { useContext } from "react";
import { Icon } from "@fluentui/react/lib/Icon";
import { useLang } from "../../../i18n/useLang";
import * as Api from "../../../ApiV2";
import { StyledThumbnailItem } from "../../../assets/style/left-sidebar/searchTab";
import loading from "../../../assets/img/loading.gif";

interface PageThumbnailProps {
  imageDetail: Api.ImageDetail;
  selectedPage: number[];
  pageIndex: number | undefined;
  thumbnailStatus: Api.HiddenEnum;
  active: boolean;
  onMouseDown?: (
    pageIndex: number, e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onClick: (
    pageIndex: number,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onContextMenu: (
    pageIndex: number,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
}

export const PageThumbnail = ({
  active,
  selectedPage,
  imageDetail,
  pageIndex,
  thumbnailStatus,
  onMouseDown,
  onContextMenu,
  onClick,
}: PageThumbnailProps) => {
  const { tr } = useLang();
  const getTextPage = () => {
    let s =
      thumbnailStatus != Api.HiddenEnum.NUMBER_0
        ? `...${thumbnailStatus == Api.HiddenEnum.NUMBER_1
          ? tr("hide")
          : tr("hideOnExtract")
        }`
        : imageDetail.isOCRDone
          ? "...Done"
          : "";

    if (!s && imageDetail.type == Api.ThumbnailType.NUMBER_1) {
      return `...TOC`;
    } else if (!s && imageDetail.type == Api.ThumbnailType.NUMBER_2) {
      return `...Blank`;
    } else {
      if (!s && imageDetail.needOCR) {
        s = tr("needOcr");
      }
      s += imageDetail.splitOnExtract
        ? " " + tr("splitFilesWhenPublishing")
        : "";
      return s;
    }
  };
  let image = imageDetail.imageBase;
  if (!imageDetail.imageBase) {
    image = loading;
  } else {
    image = `data:image/png;base64, ${imageDetail.imageBase}`;
  }
  return (
    <React.Fragment>
      <StyledThumbnailItem
        onMouseDown={(e) => {
          onMouseDown && onMouseDown(
            pageIndex || 0, e
          );
        }}
        onClick={(e) => {
          onClick(pageIndex || 0, e);
        }}
        onContextMenu={(e) => {
          onContextMenu(pageIndex || 0, e);
        }}
        itemType={
          imageDetail.type == Api.ThumbnailType.NUMBER_1 ||
            imageDetail.type == Api.ThumbnailType.NUMBER_3
            ? "NotSelect"
            : ""
        }
        id={`thumbnail-${pageIndex}`}
        className={"item-thumbnail"}
        active={active}
        selected={!!selectedPage.find((o) => o === pageIndex)}
      >
        {thumbnailStatus == Api.HiddenEnum.NUMBER_0 ? (
          <div
            className={`placeholder ${imageDetail.isVerticalView && "vertical"
              }`}
          >
            {!imageDetail.imageBase ? (
              <img src={image || ""} className={"loading-img"} alt="" />
            ) : (
              <img
                src={image || ""}
                style={{ width: "100%", margin: "auto", maxHeight: "100%" }}
                alt=""
              />
            )}
          </div>
        ) : (
          <div
            className={`placeholder ${imageDetail.isVerticalView && "vertical"
              }`}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <img
              src={"/img/eyehide.png"}
              style={{
                width: "85px",
                margin: "auto",
                maxHeight: "100%",
                position: "absolute",
                fontSize: 66,
              }}
              alt=""
            />
            <div style={{ marginTop: 100 }}>{tr("Content blocked")}</div>
          </div>
        )}
        <p>
          {tr("page")} {pageIndex} {getTextPage()}
        </p>
      </StyledThumbnailItem>
    </React.Fragment>
  );
};
