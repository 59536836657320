export const Constants = {
  RequestId: "RequestId",
  TokenThirdParty: "TokenThirdParty",
  LicenseKey: "LicenseKey",
  Email:"Email",
  FullName:"FullName",
  UserId:"UserId",
};
export const LocalStorageKey = {
  UserInfo: "_UserInfo",
  Token: "TokenNew",
  StampInfo: "StampInfoNew",
  DropdownListMenuTop: "DropdownListMenuTop",
  CUSTOM_TEMPLATES_STORAGE_KEY: "CUSTOM_TEMPLATES",
  KeywordSearch: "KeywordSearch",
  TemplateSearch: "TemplateSearchNew",
  TagSearch: "TagSearch",
  SearchTabVm: "SearchTabVm",
  PageNumbering: "PageNumbering",
  LxSetting: "LxSetting",
  DocumentHasChanged: "DocumentHasChanged",
  ReplaceWordParam: "ReplaceWordParam",
  IdentifiedKey: "IdentifiedKey",
  ConnectApp:"ConnectApp",
  OcrId: "OcrId",
  TOCSettings: "TOCSettings",
  GetOjectItem: (key: string) => {
    const s = localStorage.getItem(key)
    if (s) {
      return JSON.parse(s);
    } else {
      return null;
    }
  },
  SetItem: (obj: any, key: string) => {
    localStorage.setItem(key, JSON.stringify(obj))
  }
};
export const SearchTypeKey = {
  PhoneNumber: "PhoneNumber",
  Email: "Email",
  Diseases: "Diseases",
  Currency: "Currency",
  Number: "Number",
  WebAddress: "WebAddress",
  AssistiveProduct: "AssistiveProduct",
  AssistiveProductFuzzy: "AssistiveProductFuzzy",
  BelgianMunicipalities: "BelgianMunicipalities",
  PoliceZones: "PoliceZones",
  Cvr: "Cvr",
  CreditCard: "CreditCard",
  Cpr: "Cpr",
  Bsn: "Bsn",
  Nrn: "Nrn",
  Name: "Name",
  NameAccuracy: "NameAccuracy",
  Address: "Address",
  CustomTemplate: "CustomTemplate",
  Keyword: "Keyword",
  Tag: "Tag",
  None: "None",
  Date: "Date",
  BELicensePlates: "BELicensePlates",
  BEPoliceReport: "BEPoliceReport",
  BECompanyID: "BECompanyID",
  BEHandelsregisternummer: "BEHandelsregisternummer",
  Medication: "Medication",
};
export const DataCache = {
  docuViewareID: () => {
    return localStorage.getItem("docuViewareID");
  },
  InputOpenFile: null as any,
  REACT_APP_API_ENDPOINT: "",
  REACT_APP_CompanyCode: "",
  OCRAPI: "",
  RegisterLink: "",
  StatusLoadAllThumbnail: false
};

export const AnnoType = {
  BOX: "Box",
  TAG: "Tag",
  NOTE: "Note",
  STAMP: "Stamp",
  REPLACEBOX: "ReplaceBox",
  REPLACE: "Replace",
  WORD: "Word",
  FrontPage: "FrontPage",
  LINE: "LINE",
  HIGHLIGHT: "Highlight",
};
export const CompanyCode = {
  Nova: "nova",
  lc: "lc",
};
