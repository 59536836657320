import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import * as uuid from "uuid";
import * as ApiV2 from "../../ApiV2";
import BoxCommand from "../../command/box-command";
import HighlightTextCommand from "../../command/hightlight-Command";
import LineCommand from "../../command/line-command";
import HighLightSectionCommand from "../../command/section-command";
import { AnnoType, DataCache } from "../../config/constant";
import {
  Annotation,
  IMouseState,
  IPageState,
  ITabState,
  IWordState,
  leftSidebarState,
  lxSettingState,
  modalContainerState,
  mouseState,
  pageSelectedState,
  tabState,
  tagState,
} from "../../recoil";
import {
  DocuVieware,
  docuviewareMainState,
} from "../../recoil/docuviewareState";
import { annotationService } from "../../service/annotationService";
import { documentService } from "../../service/document";
import { lineService } from "../../service/lineService";
import {
  FocusedText,
  GetSelectionBoxParameterFromWords,
} from "../../utils/helpers";
import { TabContextMenuProps } from "./menuContext/mainMenuContext";
import { MouseMode } from "../../recoil/mouseState";
import { CacheApp } from "../../App";
let clickCount = 0;
let resetclickCountTimeOut: NodeJS.Timeout | null = null;
let hightlightClickTimeOut: NodeJS.Timeout | null = null;
let IsShowTooltip = false;
const contextualMenuOpen = false;
let timeOutRawHightLightWord: any = null;
let regionsMask: any[] = [];
let timerMouseEnter: any;
let _objMouseMove: any | null = null;
let currentselectedWords: ApiV2.LxWordResponse[] = [];
export let _SelectedText = "";
export let _mouseModeState: IMouseState | null = null;



export const UseRegisterEventDocuvieware = () => {
  const [docuviewareMain, setDocuviewareMain] =
    useRecoilState(docuviewareMainState);
  const [lxSetting] = useRecoilState(lxSettingState);
  const [pageState, setPageState] =
    useRecoilState<IPageState>(pageSelectedState);
  const [mouseModeState, setMouseMode] =
    useRecoilState<IMouseState>(mouseState);
  const [tabCurrentState, setTabState] = useRecoilState<ITabState>(tabState);
  const [, setLeftSidebarState] = useRecoilState(leftSidebarState);
  const [, setTagState] = useRecoilState(tagState);
  const [modalContainer, setModalContainerState] =
    useRecoilState(modalContainerState);
  const [mainMenuContext, setMainMenuContextState] =
    useState<TabContextMenuProps | null>();
  useEffect(() => {
    CacheApp.MainMenuContext = { ...CacheApp.MainMenuContext, IsShowContextMenu: mainMenuContext?.IsShowContextMenu };
  }, [mainMenuContext?.IsShowContextMenu]);
  useEffect(() => {
    _mouseModeState = mouseModeState;
  }, [mouseModeState]);

  useEffect(() => {
    CacheApp.PageState = pageState;
  }, [pageState]);
  useEffect(() => {
    CacheApp.DocuviewareMain = docuviewareMain;
  }, [docuviewareMain]);
  function RemoveSelectedTextRegionByColor(
    listRegion: any[],
    dom: any,
    colorName: string,
    isAll = false
  ) {
    if (!listRegion || listRegion?.length == 0) return;
    const arr = [...listRegion];
    if (isAll == true) {
      dom.SelectedTextRegions.clear();
    }
    if (arr) {
      const region = arr.filter((o) => {
        return o.outerHTML.indexOf(colorName) > 0;
      });
      if (region) {
        region.forEach((element) => {
          const index = arr.indexOf(element);
          listRegion.splice(index, 1);
          if (element.parentNode) {
            element.parentNode.removeChild(element);
          }
        });
      }
    }
  }
  function IsWordSelected(x: ApiV2.LxWordResponse, posX: any, posY: any) {
    let result = false;
    if (x.rects) {
      const x1 = x.rects[0] as ApiV2.RectangleF;
      result =
        (x1?.left || 0) <= posX &&
        (x1.left || 0) + (x1.width || 0) > posX &&
        (x1.top || 0) <= posY &&
        (x1.top || 0) + (x1.height || 0) >= posY;
      if (!result && x.rects.length > 1) {
        const x2 = x.rects[1] as ApiV2.RectangleF;
        result =
          (x2.left || 0) <= posX &&
          (x2.left || 0) + (x2.width || 0) > posX &&
          (x2.top || 0) <= posY &&
          (x2.top || 0) + (x2.height || 0) >= posY;
      }
      return result;
    }
    return result;
  }
  const setDataPageChange = (pageNo: number) => {
    if ((CacheApp.PageState?.oldCurrentPage || 0) > 0 && CacheApp.PageState?.oldCurrentPage != pageNo) {
      return;
    }
    const pageSelected =
      (CacheApp.DocuviewareMain?.DocuviewareInit?.pages || []).find(
        (o) => o.viewerPageNo == pageNo
      ) || null;
    setPageState((cur) => {
      return { ...cur, currentPage: pageNo, PageObj: pageSelected };
    });
    documentService.SelectedPage(
      (res: any) => {
        if (!res) return;
        const data = JSON.parse(res);
        if (data) {
          const pageDetail = data as ApiV2.WordInPage;
          setDocuviewareMain((cur) => {
            return { ...cur, pageDetails: [pageDetail] };
          });
        }
        const tab = CacheApp.DocuviewareMain?.DocuviewareInit?.tabDetails?.find((x) => {
          if (x.startPage && x.totalPage) {
            return x.startPage <= pageNo && pageNo < x.startPage + x.totalPage;
          }
          return false;
        });
        if (tab) {
          setTabState({ ...tabCurrentState, selectedTab: tab });
        }
        setTimeout(() => {
          if (pageNo === CacheApp.PageState?.rectangleFSelected?.pageNo) {
            FocusedText(
              CacheApp.PageState?.rectangleFSelected?.rectangleSelections,
              CacheApp.PageState?.rectangleFSelected?.pageNo || 0,
              DataCache.docuViewareID() || "",
              CacheApp.PageState?.rectangleFSelected?.color
            );
          }
        }, 100);
      },
      DataCache.docuViewareID(),
      {
        Data: JSON.stringify({ currentPage: pageNo }),
        Type: "SelectedPageCommand",
      }
    );
  };
  const onMenuContext = (e: any, annot?: Annotation) => {
    const root = DocuViewareAPI.Root(DataCache.docuViewareID());
    const mo = root.Internal.Text.captureSelectedText(
      e.clientX,
      e.clientY,
      e.clientX,
      e.clientY,
      !1,
      !1
    )
    const mousePos = mo && mo.length >= 1 ? mo[0].mousePos : {};
    annot = annot || (CacheApp.AnnotState || {} as Annotation);
    e?.preventDefault();
    e?.stopPropagation();
    const param = {
      regionsMask: CacheApp.WordCurrentState?.regionsMaskLasted,
      targetPoint: { x: e.clientX, y: e.clientY },
      PageSelected:
        (docuviewareMain?.DocuviewareInit?.pages || []).find(
          (o) => o.viewerPageNo == pageState.currentPage
        ) || null,
      WordDetected: CacheApp.WordCurrentState?.wordDetected,
      AnnotationId: annot?.AnnotationId,
      AnnotationTagType: annot?.TagType,
      BoxId:
        annot?.TagType === AnnoType.BOX ||
          annot?.TagType === AnnoType.REPLACEBOX
          ? annot?.ObjId || ""
          : null,
      LastMousePos: mousePos,
      ObjMouseMove: _objMouseMove,
      NoteId:
        annot?.TagType === AnnoType.NOTE
          ? annot?.ObjId || ""
          : null,
      ReplaceId:
        annot?.TagType === AnnoType.REPLACE
          ? annot?.ObjId
          : null,
      TagId:
        annot?.TagType === AnnoType.TAG
          ? annot?.ObjId
          : null,
      refreshMenu: true,
    } as TabContextMenuProps;
    CacheApp.MainMenuContext = {
      ...CacheApp.MainMenuContext,
      ...param,
      IsShowContextMenu: true,
    }
    setMainMenuContextState((cur) => {
      return {
        ...cur,
        ...param,
        IsShowContextMenu: true,
      };
    });
  }
  const RegisterEventDocuvieware = (onAfterInit: any) => {
    function isInitialized(reloadDocument: any) {
      const root = DocuViewareAPI.Root(DataCache.docuViewareID());
      if (root) {
        root.Internal.Annotations.CANVAS_PADDING = 4;
      }
      const initialize = DocuViewareAPI.IsInitialized(
        DataCache.docuViewareID()
      );
      if (initialize) {
        onAfterInit(reloadDocument);
        return;
      }
    }
    DocuViewareAPI.RegisterOnNewDocumentLoaded(
      DataCache.docuViewareID(),
      (reloadDocument: any) => {
        isInitialized(reloadDocument);
        setTimeout(() => {

          documentService.centerScroll();
        }, 1200);
      }
    );
    const a = DocuViewareAPI.RegisterOnPageChanged(
      DataCache.docuViewareID(),
      async () => {
        const pageNo = DocuViewareAPI.GetCurrentPage(DataCache.docuViewareID());
        setDataPageChange(pageNo || CacheApp.PageState?.currentPage);
      }
    );
    DocuViewareAPI.RegisterOnGetDataBeforePageChanged(
      DataCache.docuViewareID(),
      () => { }
    );

    DocuViewareAPI.RegisterOnMouseMove(
      DataCache.docuViewareID(),
      (data: any) => {
        if (CacheApp.MainMenuContext?.IsShowContextMenu) {
          return;
        }
        _objMouseMove = data;
        regionsMask = [];
        let word: ApiV2.LxWordResponse | null | undefined = null;
        const mousePos = data && data.mousePos ? data.mousePos : {};
        if (!CacheApp.MainMenuContext?.IsShowContextMenu) {
          CacheApp.MainMenuContext = { ...CacheApp.MainMenuContext, LastMousePos: mousePos, regionsMask: [], }
          setMainMenuContextState((cur) => {
            return {
              ...cur,
              regionsMask: [],
            };
          });
        }
        const pageDetail = CacheApp.DocuviewareMain?.pageDetails?.find(
          (x) => x.pageNo === data.pageNo
        );
        const page = (CacheApp.DocuviewareMain?.DocuviewareInit?.pages || []).find(
          (o) => o.viewerPageNo == data.pageNo
        );
        if (pageDetail && pageDetail.words) {
          let posX = data.mousePos.Left / 72;
          let posY = data.mousePos.Top / 72;
          if (page?.rotationAngles == 270) {
            const a = {
              L: data.mousePos.Top / 72,
              T: (page?.pageHeight || 0) - data.mousePos.Right / 72,
            };
            posX = a.L;
            posY = a.T;
          }
          if (page?.rotationAngles == 90) {
            const a = {
              L: (page?.pageWidth || 0) - data.mousePos.Bottom / 72,
              T: data.mousePos.Left / 72,
            };
            posX = a.L;
            posY = a.T;
          }
          if (page?.rotationAngles == 180) {
            const a = {
              L: (page?.pageWidth || 0) - data.mousePos.Right / 72,
              T: (page?.pageHeight || 0) - data.mousePos.Top / 72,
            };
            posX = a.L;
            posY = a.T;
          }
          word = pageDetail.words.find((x: any) => {
            return IsWordSelected(x, posX, posY);
          });
          if (
            _mouseModeState?.mouseMode == MouseMode.Select ||
            _mouseModeState?.mouseMode == MouseMode.Tag
          ) {
            rawHightLightWord(word || {}, data);
          }
        }
        if (_mouseModeState?.mouseMode == MouseMode.Mask) {
          regionsMask = data.regions;
        }
        CacheApp.WordCurrentState = { ...CacheApp.WordCurrentState, wordDetected: word || null };
      }
    );

    DocuViewareAPI.RegisterOnAnnotationAdded(
      DataCache.docuViewareID(),
      async (obj: any) => {
        const anno = obj.anno;
        if (_mouseModeState?.mouseMode === -1) {
          if (!anno) return;
          let height = anno?.properties?.height;
          if (anno?.properties?.width > anno?.properties?.height) {
            height = anno?.properties?.width;
          }
          const addLineParam: ApiV2.AddLineParam = {
            left: anno?.properties?.left,
            top: anno?.properties?.top,
            borderWidth: anno?.properties?.borderWidth,
            height: anno?.properties?.height,
            width: anno?.properties?.width,
            color: rgbaToHex(anno?.properties?.strokeColor),
            id: anno?.id,
          };
          const currentPage = DocuViewareAPI.GetCurrentPage(
            DataCache.docuViewareID()
          );
          const addLine = {
            viewerPageNo: currentPage,
            addLineParam: addLineParam,
          };
          LineCommand(
            DataCache.docuViewareID(),
            addLine,
            null,
            null,
            null,
            () => {
              setLeftSidebarState((cur) => {
                return {
                  ...cur,
                  refreshTabActive: { IsRefresh: true, TabIndex: -1 },
                };
              });
              setMouseMode({ mouseMode: -1 });

              lineService.onLineClick(
                DataCache.docuViewareID() || "",
                currentPage,
                CacheApp.LineBorderWidth?.borderAdd || 0,
                CacheApp.LineBorderWidth?.colorAdd || "#333"
              );
            }
          );
        }
      }
    );
    DocuViewareAPI.RegisterOnAnnotationMouseEnter(
      DataCache.docuViewareID(),
      (obj: any) => {
        if (CacheApp.MainMenuContext?.IsShowContextMenu) return;
        window.clearTimeout(timerMouseEnter);
        timerMouseEnter = window.setTimeout(() => {
          const rootDocuVieware = DocuViewareAPI.Root(
            DataCache.docuViewareID()
          );
          if (!rootDocuVieware) return;
          const dom = rootDocuVieware.dom;
          if (_mouseModeState?.mouseMode != 3) {
            rootDocuVieware.Internal.Text.clearSelection();
            RemoveSelectedTextRegionByColor(
              dom.SelectedTextRegions._cache,
              dom,
              "rgba(0, 0, 0, 0.3)"
            );
          }
          const clientTag = obj.id.clientTag.toString() + "_";
          const subject = obj.id.subject.toString();
          // if (clientTag.indexOf(AnnoType.LINE) >= 0) {
          //   setLineState({ border: obj.id.properties?.borderWidth, color: rgbaToHex(obj.id.properties?.strokeColor) });
          // }

          CacheApp.AnnotState = {
            ...CacheApp.AnnotState,
            AnnotationId: obj.id.id,
            ObjId: clientTag.split("_")[1] || "",
            Data: clientTag.split("_")[1],
            TagType: clientTag.split("_")[0],
            ClientTag: clientTag.split("_")[0],
            Subject: subject,
          }
          if (
            CacheApp.AnnotState.TagType === AnnoType.REPLACEBOX &&
            obj.id.properties.type === 2
          ) {
            const pageNo = DocuViewareAPI.GetCurrentPage(
              DataCache.docuViewareID()
            );
            if (!IsShowTooltip) {
              annotationService.AddAnnotations(
                DataCache.docuViewareID(),
                {
                  Data: JSON.stringify({
                    pageNo: pageNo,
                    id: CacheApp.AnnotState.ObjId || "",
                  }),
                  Type: "ShowReplaceBox",
                },
                () => {
                  IsShowTooltip = true;
                }
              );
            }
            return;
          } else if (
            CacheApp.AnnotState.TagType === AnnoType.REPLACE &&
            obj.id.properties.type === 2
          ) {
            const pageNo = DocuViewareAPI.GetCurrentPage(
              DataCache.docuViewareID()
            );
            if (!IsShowTooltip) {
              annotationService.AddAnnotations(
                DataCache.docuViewareID(),
                {
                  Data: JSON.stringify({
                    viewerPageNo: pageNo,
                    replaceObjId: CacheApp.AnnotState.ObjId || "",
                    rectangleWords: [
                      {
                        left: obj.id.rect.Left || 0,
                        top: obj.id.rect.Top || 0,
                        width: obj.id.rect.Width || 0,
                        height: obj.id.rect.Height || 0,
                      },
                    ],
                  } as ApiV2.ReplaceTooltipWeb),
                  Type: "ShowReplace",
                },
                () => {
                  IsShowTooltip = true;
                }
              );
            }
          }
        }, 200);
      }
    );
    DocuViewareAPI.RegisterOnAnnotationMouseLeave(
      DataCache.docuViewareID(),
      (obj: any) => {
        if (CacheApp.MainMenuContext?.IsShowContextMenu) return;
        window.clearTimeout(timerMouseEnter);
        const tag = obj.id.clientTag.toString().split("_")[0];
        CacheApp.AnnotState = {
          ...CacheApp.AnnotState, AnnotationId: "",
          Data: "",
          TagType: "",
          ObjId: "",
          ClientTag: '',
          Subject: ''
        }
        if (tag === AnnoType.REPLACE && obj.id.properties.type === 2) {
          IsShowTooltip = false;
          annotationService.AddAnnotations(
            DataCache.docuViewareID(),
            {
              Type: "DeleteReplaceTooltip",
            },
            null
          );
          return;
        } else if (
          tag === AnnoType.REPLACEBOX &&
          obj.id.properties.type === 2
        ) {
          IsShowTooltip = false;
          annotationService.AddAnnotations(
            DataCache.docuViewareID(),
            {
              Type: "DeleteReplaceBoxTooltip",
            },
            null
          );
          return;
        }
      }
    );

    DocuViewareAPI.RegisterOnAreaSelected(DataCache.docuViewareID(), () => {
      const currentPage = DocuViewareAPI.GetCurrentPage(
        DataCache.docuViewareID()
      );
      const rect = DocuViewareAPI.GetSelectionAreaCoordinates(
        DataCache.docuViewareID()
      );
      if (contextualMenuOpen) return;
      if (rect) {
        if (_mouseModeState?.mouseMode == 0) {
          const pageNo = DocuViewareAPI.GetCurrentPage(
            DataCache.docuViewareID()
          );
          const param = {
            viewerPageNo: pageNo,
            addBoxParam: {
              left: rect.left,
              top: rect.top,
              width: rect.width,
              height: rect.height,
              id: uuid.v4(),
            },
          };
          BoxCommand(DataCache.docuViewareID(), param, null, () => {
            setLeftSidebarState((cur) => {
              return {
                ...cur,
                refreshTabActive: { IsRefresh: true, TabIndex: -1 },
              };
            });
          });
        } else if (_mouseModeState?.mouseMode == 1) {
          const selectionBox: ApiV2.RectangleSelection[] = [
            {
              left: rect.left,
              top: rect.top,
              width: rect.width,
              height: rect.height,
              viewerPageNo: currentPage,
            },
          ];
          let mode = 1;
          // nhấn giữ shift thì UnHighLightWords
          if (CacheApp.KeyCode === 17) {
            mode = 0;
          }
          const param: ApiV2.HighLightOrUnHighLightWords = {
            rectangleSelection: selectionBox,
            mode: mode,
            viewerPageNo: CacheApp.PageState?.currentPage,
          };
          HighLightSectionCommand(DataCache.docuViewareID(), param, () => {
            CacheApp.KeyCode = (null);
            setLeftSidebarState((cur) => {
              return {
                ...cur,
                refreshTabActive: {
                  IsRefresh: true,
                  TabIndex: -1,
                  ForceSearch: true,
                },
              };
            });
          });
        }
      }

      DocuViewareAPI.Root(
        DataCache.docuViewareID()
      ).Internal.Selection.clearSelectionArea();
    });

    DocuViewareAPI.RegisterOnAnnotationMouseDown(
      DataCache.docuViewareID(),
      async (obj: any) => {
        const objAnno = obj.data;
        const rootDocuVieware = DocuViewareAPI.Root(DataCache.docuViewareID());
        rootDocuVieware.Internal.Text.clearSelection();
        const dom = rootDocuVieware.dom;
        RemoveSelectedTextRegionByColor(
          dom.SelectedTextRegions._cache,
          dom,
          "rgba(0, 0, 0, 0.3)"
        );
        const clientTag = objAnno.clientTag.toString() + "_";
        if (objAnno.clientTag.toString().indexOf("LINE") >= 0) {
          CacheApp.LineBorderWidth = {
            ...CacheApp.LineBorderWidth,
            borderEdit: objAnno.properties?.borderWidth,
            colorEdit: rgbaToHex(objAnno.properties?.strokeColor),
          }
        }

        const subject = objAnno.subject.toString();
        CacheApp.AnnotState = {
          ...CacheApp.AnnotState,
          AnnotationId: objAnno.id,
          ObjId: clientTag.split("_")[1] || "",
          Data: clientTag.split("_")[1],
          TagType: clientTag.split("_")[0],
          ClientTag: clientTag.split("_")[0],
          Subject: subject,
        }
        if (3 == obj.event.which) {
          onMenuContext(obj.event, CacheApp.AnnotState);
        }
      }
    );
    DocuViewareAPI.RegisterOnAnnotationStopEdit(
      DataCache.docuViewareID(),
      () => {
        setLeftSidebarState((cur) => {
          return {
            ...cur,
            refreshTabActive: { IsRefresh: true, TabIndex: -1 },
          };
        });
      }
    );
    DocuViewareAPI.RegisterOnMouseDown(DataCache.docuViewareID(), (e: any) => {
      const root = DocuViewareAPI.Root(DataCache.docuViewareID());
      if (3 != e.which) {
        if (_mouseModeState?.mouseMode != 3 && _mouseModeState?.mouseMode != 4) {
          root.events.isSelectingText = false;
        } else {
          root.events.isSelectingText = true;
        }
      } else {
        onMenuContext(e);
      }

    });
    DocuViewareAPI.RegisterOnMouseUp(
      DataCache.docuViewareID(),
      async (obj: any) => {
        clickCount++;
        setTagState((cur) => {
          return { ...cur, idTagSelected: "" };
        });
        if (_mouseModeState?.mouseMode === 0) return;
        const root = DocuViewareAPI.Root(DataCache.docuViewareID());
        const word = CacheApp.WordCurrentState?.wordDetected;
        const dom = root.dom;
        if (regionsMask) {
          _SelectedText = DocuViewareAPI.GetSelectedText(
            DataCache.docuViewareID()
          );
        } else {
          _SelectedText = "";
        }
        //tag
        if (_mouseModeState?.mouseMode === 4) {
          RemoveSelectedTextRegionByColor(
            dom.SelectedTextRegions._cache,
            dom,
            "rgba(0, 0, 0, 0.3)"
          );
          if (word) {
            if (currentselectedWords) {
              if (currentselectedWords.length == 0) {
                RemoveSelectedTextRegionByColor(
                  dom.SelectedTextRegions._cache,
                  dom,
                  "rgba(24, 138, 255, 0.4)"
                );
              }
              const re1 = currentselectedWords.find((o) => {
                if (!o || !word.id) return false;
                return (
                  (o.id && o.id + 1 === word.id) ||
                  (o.id && o.id - 1 === word.id)
                );
              });
              if (
                !re1 &&
                re1 === undefined &&
                currentselectedWords.length > 0
              ) {
                RemoveSelectedTextRegionByColor(
                  dom.SelectedTextRegions._cache,
                  dom,
                  "rgba(24, 138, 255, 0.4)"
                );
                currentselectedWords = [];
              }
              const re = currentselectedWords.find((o) => {
                if (!o || !word.id) return false;
                return o.id == word.id;
              });
              if (re && re != undefined) return;
              currentselectedWords = [...currentselectedWords, word];
            } else {
              currentselectedWords = [...currentselectedWords, word];
            }
            currentselectedWords.forEach((item) => {
              item.rects?.forEach((element) => {
                const e = {
                  left: ((element.left || 0) * 72) / _objMouseMove.scale,
                  top: ((element.top || 0) * 72) / _objMouseMove.scale,
                  width: ((element.width || 0) * 72) / _objMouseMove.scale,
                  height: ((element.height || 0) * 72) / _objMouseMove.scale,
                };
                dom.SelectedTextRegions.addNew(
                  CacheApp.PageState?.currentPage,
                  e,
                  "rgba(24, 138, 255, 0.4)",
                  null,
                  2,
                  null
                );
              });
            });
          } else {
            currentselectedWords = [];
          }

          CacheApp.WordCurrentState = { ...CacheApp.WordCurrentState, currentselectedWords: currentselectedWords }
          if (currentselectedWords.length === 0) {
            RemoveSelectedTextRegionByColor(
              dom.SelectedTextRegions._cache,
              dom,
              "rgba(24, 138, 255, 0.4)"
            );
          }
          return;
        }
        if (
          word &&
          word.rects &&
          (_mouseModeState?.mouseMode === 2 || _mouseModeState?.mouseMode === 1)
        ) {
          if (clickCount == 2) {
            HightLightLine();
            if (hightlightClickTimeOut) {
              clearTimeout(hightlightClickTimeOut);
            }
            return;
          }
          hightlightClickTimeOut = setTimeout(function () {
            const selectionBox = GetSelectionBoxParameterFromWords([word]);
            const param: ApiV2.HighLightOrUnHighLightWords = {
              rectangleSelection: selectionBox,
              mode: -1,
              viewerPageNo: CacheApp.PageState?.currentPage,
            };
            HighlightTextCommand(DataCache.docuViewareID(), param, () => {
              setLeftSidebarState((cur) => {
                return {
                  ...cur,
                  refreshTabActive: {
                    IsRefresh: true,
                    TabIndex: -1,
                    ForceSearch: true,
                  },
                };
              });
            });
          }, 200);

        }
        if (regionsMask && _mouseModeState?.mouseMode === MouseMode.Mask) {
          if (!lxSetting?.autoHighlightMask) {
            CacheApp.WordCurrentState = { ...CacheApp.WordCurrentState, regionsMaskLasted: regionsMask || [] }
            setTimeout(() => {
              onMenuContext(obj);
            }, 200);
          } else {
            const pageNo = DocuViewareAPI.GetCurrentPage(
              DataCache.docuViewareID()
            );
            CacheApp.WordCurrentState = { ...CacheApp.WordCurrentState, regionsMaskLasted: regionsMask || [] }
            documentService.HighLightOrUnHighLightMask(
              -1,
              pageNo,
              regionsMask,
              () => {
                CacheApp.AnnotState = { ...(CacheApp.AnnotState || {}), TagType: '' };
                // setAnnotationState({ ...annotState, TagType: "" });
                setLeftSidebarState((cur) => {
                  return {
                    ...cur,
                    refreshTabActive: {
                      IsRefresh: true,
                      TabIndex: -1,
                      ForceSearch: true,
                    },
                  };
                });
              }
            );
          }
        }
        else {
          CacheApp.WordCurrentState = { ...CacheApp.WordCurrentState, regionsMaskLasted: [] }
        }
        if (resetclickCountTimeOut) {
          clearTimeout(resetclickCountTimeOut);
        }
        resetclickCountTimeOut = setTimeout(function () {
          clickCount = 0;
          if (
            _mouseModeState?.mouseMode != 3 &&
            _mouseModeState?.mouseMode != 4
          ) {
            RemoveSelectedTextRegionByColor(
              dom.SelectedTextRegions._cache,
              dom,
              "",
              true
            );
            CacheApp.WordCurrentState = { ...CacheApp.WordCurrentState, wordDetected: null }
            CacheApp.AnnotState = {
              ...CacheApp.AnnotState,
              AnnotationId: "",
              Data: "",
              TagType: "",
              ObjId: "",
              ClientTag: '',
              Subject: ''
            }
          }
        }, 200);
      }
    );
    DocuViewareAPI.RegisterOnAnnotationMouseDblClick(
      DataCache.docuViewareID(),
      (obj: any) => {
        if (obj.data.clientTag.toString().toLowerCase().indexOf("toc") >= 0) {
          const istitle =
            obj.data.clientTag.toString().toLowerCase().indexOf("title") >= 0;
          setModalContainerState({
            ...modalContainer,
            showEditHeaderToc: true,
            EditTocHeaderParam: {
              text: obj.data.properties.text,
              editTitle: istitle,
              tocColumEnumType: istitle
                ? ApiV2.TocColumEnum.NUMBER_0
                : (parseInt(
                  obj.data.clientTag.toString().split("_")[1]
                ) as ApiV2.TocColumEnum),
            },
          });
        }
      }
    );
  };
  const rgbaToHex = (color: string): string => {
    if (/^rgb/.test(color)) {
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");

      // rgb to hex
      // eslint-disable-next-line no-bitwise
      let hex = `#${(
        (1 << 24) +
        (parseInt(rgba[0], 10) << 16) +
        (parseInt(rgba[1], 10) << 8) +
        parseInt(rgba[2], 10)
      )
        .toString(16)
        .slice(1)}`;

      // added alpha param if exists
      if (rgba[4]) {
        const alpha = Math.round(0o1 * 255);
        const hexAlpha = (alpha + 0x10000)
          .toString(16)
          .substr(-2)
          .toUpperCase();
        hex += hexAlpha;
      }

      return hex;
    }
    return color;
  };
  const HightLightLine = () => {
    const word = CacheApp.WordCurrentState?.wordDetected;
    if (
      word &&
      word.rects &&
      (mouseModeState.mouseMode === 2 || mouseModeState.mouseMode === 1)
    ) {
      const param: ApiV2.HighLightUnHighLightLine = {
        pageNo: CacheApp.PageState?.currentPage,
        isHotKey: true,
        x: CacheApp.MainMenuContext?.LastMousePos?.Right / 72,
        y: CacheApp.MainMenuContext?.LastMousePos?.Top / 72,
      };
      documentService.HighLightLine(param, () => {
        setLeftSidebarState((cur) => {
          return {
            ...cur,
            refreshTabActive: { IsRefresh: true, TabIndex: -1 },
          };
        });
      });
    }
  };
  const rawHightLightWord = (word: ApiV2.LxWordResponse, objMouseMove: any) => {
    if (!objMouseMove || CacheApp.MainMenuContext?.IsShowContextMenu || !word) return;
    if (timeOutRawHightLightWord) {
      clearTimeout(timeOutRawHightLightWord);
      timeOutRawHightLightWord = null;
    }
    timeOutRawHightLightWord = setTimeout(() => {
      const root = DocuViewareAPI.Root(DataCache.docuViewareID());
      if (!root) return;
      root.Internal.Text.clearSelection();
      const dom = root.dom;
      if (
        _mouseModeState?.mouseMode == 1 ||
        _mouseModeState?.mouseMode == 2 ||
        _mouseModeState?.mouseMode == 4
      ) {
        RemoveSelectedTextRegionByColor(
          dom.SelectedTextRegions._cache,
          dom,
          "rgba(0, 0, 0, 0.3)"
        );
        if (word) {
          let e: any;
          if (word.rects) {
            e = {
              left: ((word.rects[0].left || 0) * 72) / objMouseMove.scale,
              top: ((word.rects[0].top || 0) * 72) / objMouseMove.scale,
              width: ((word.rects[0].width || 0) * 72) / objMouseMove.scale,
              height: ((word.rects[0].height || 0) * 72) / objMouseMove.scale,
            };
          }
          if (word.rects && objMouseMove) {
            DocuViewareAPI.Root(
              DataCache.docuViewareID()
            ).dom.SelectedTextRegions.addNew(
              objMouseMove.pageNo,
              e,
              "rgba(0, 0, 0, 0.3)",
              null,
              1,
              null
            );
          }
          return;
        }
      }
    }, 100);
  };
  return { RegisterEventDocuvieware, mainMenuContext, setMainMenuContextState };
};
